const Translations = Object.freeze({
  'enter_experience': {
    en: 'ENTER THE EXPERIENCE',
    es: 'ENTRAR A LA EXPERIENCIA',
    it: "ENTRA NELL'ESPERIENZA",
    fr: "ENTREZ DANS L'EXPÉRIENCE",
  },
  'login': {
    en: 'Login',
    es: 'Iniciar sesión',
    it: 'Accedere',
    fr: 'Connexion',
  },
  'editor': {
    en: 'Editor',
    es: 'Editor',
    it: 'Editore',
    fr: 'Éditeur',
  },
  'website': {
    en: 'Website',
    es: 'Sitio web',
    it: 'Sito web',
    fr: 'Site Internet',
  },
  'cookies-policy': {
    en: 'Cookies policy',
    es: 'Política de cookies',
    it: 'Politica sui cookie',
    fr: 'Politique de cookies',
  },
  'close-menu': {
    en: 'Close menu',
    es: 'Cerrar menú',
    it: 'Chiudi menu',
    fr: 'Fermer le menu',
  },
  'go-back': {
    en: 'Go back',
    es: 'Volver',
    it: 'Torna indietro',
    fr: 'Retourner',
  },
  'GO-BACK': {
    en: 'GO BACK',
    es: 'VOLVER',
    it: 'TORNA INDIETRO',
    fr: 'RETOURNER',
  },
  'logged-in': {
    en: 'Logged In',
    es: 'Conectado',
    it: 'Connesso',
    fr: 'Connecté',
  },
  'login...': {
    en: 'Login...',
    es: 'Acceso...',
    it: 'Login...',
    fr: 'Connexion...',
  },
  'entering': {
    en: 'ENTERING...',
    es: 'ENTRANDO...',
    it: 'ENTRANDO...',
    fr: 'ENTRER...',
  },
  'log-out': {
    en: 'Log out',
    es: 'Cerrar sesión',
    it: 'Disconnettersi',
    fr: 'Se déconnecter',
  },
  'welcome_popup_title': {
    en: 'Welcome to Ferraro Experience',
    es: 'Bienvenido a Experiencia Ferraro',
    it: 'Benvenuto in Ferraro Experience',
    fr: "Bienvenue à l'expérience Ferraro",
  },
  'welcome_popup_message': {
    en: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quae eveniet ex saepe, corrupti inventore nulla quisquam quas non architecto optio quibusdam.',
    es: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quae eveniet ex saepe, corrupti inventore nulla quisquam quas non architecto optio quibusdam.',
    it: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quae eveniet ex saepe, corrupti inventore nulla quisquam quas non architecto optio quibusdam.',
    fr: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quae eveniet ex saepe, corrupti inventore nulla quisquam quas non architecto optio quibusdam.',
  },
  'contact_button': {
    en: 'Meet us',
    es: 'Conócenos',
    it: 'Meet us',
    fr: 'Rencontre nous',
  },
  'video': {
    en: 'Video',
    es: 'Vídeo',
    it: 'Video',
    fr: 'Vidéo',
  },
  'gallery': {
    en: 'Gallery',
    es: 'Galería',
    it: 'Galleria',
    fr: 'Galerie',
  },
  '': {
    en: '',
    es: '',
    it: '',
    fr: '',
  },
});

export default Translations;
