import * as THREE from 'three';
import AppData from './appData';
import EventsManager from './events-manager';
import { gsap } from 'gsap';
// Import Swiper JS:
import Swiper from 'swiper/bundle';
// Import Swiper styles:
import 'swiper/css/bundle';
import Localization from '../utils/localization/localization';

export default class UIManager {
    constructor(scene, renderer, canvas) {
        AppData.uiManager = this; // Save reference

        this.scene = scene;
        this.renderer = renderer;
        this.canvas = canvas;

        this.camera = null;
        this.cameraTweenDuration = 0.75;
        this.previousCameraPosition = new THREE.Vector3();
        this.previousCameraTarget = new THREE.Vector3();
        this.cameraisTweening = false;
        this.isZoomed = false;
        this.descriptionVisible = false;
        this.galleryImgIndex = 0;
        this.currentDescriptionPoint = null;
        this.roomButtons = [];
        this.roomPoints = [];
        this.roomPointsDatas = [];
        this.descriptionVideosExtraElements = [];

        this.getElements();
        this.initialize();
        this.subscribeToEvents();
    }

    getElements() {
        // Backgrounds:
        this.introBg = document.querySelector('#intro-background');
        this.roomSelectorBg = document.querySelector('#room-selector-background');
        this.roomBg = document.querySelector('#room-background');
        this.modelBg = document.querySelector('#model-background');

        // Menu:
        /* this.enterExperienceDiv = document.querySelector('#experience-div');
        this.loginDiv = document.querySelector('#login-div');
        this.enterExperienceBtn = document.querySelector('#enterExperienceBtn');
        this.enterExperienceBtn.addEventListener('click', () => {
            this.closeDescription();
        });
        this.experienceLoginBtn = document.querySelector('#experience-login');
        this.experienceLoginBtn.addEventListener('click', () => {
            this.closeDescription();
        }); */
        this.editorBtn = document.querySelector('#menu-editor-button');

        // Popup:
        this.popupContainer = document.querySelector('#popup-message-container');

        this.popupCloseBtn = document.querySelector('#popup-message-close-button');
        this.popupCloseBtn.addEventListener('click', () => {
            this.showHidePopup(false);
        });

        this.popupTitleElement = document.querySelector('#popup-message-title');
        this.popupContentElement = document.querySelector('#popup-message-content');

        // Main menu:
        this.rotateDeviceContainer = document.querySelector('#rotate-device-container');
        this.loadingContainerElement = document.querySelector('#loading-container');
        this.introVideoContainerElement = document.querySelector('#intro-video-container');
        this.introVideoIframeElement = document.querySelector('#intro-video');
        this.introVideoCloseBtnElement = document.querySelector('#intro-video-close-button');
        this.introVideoCloseBtnElement.addEventListener('click', () => {
            this.showHideIntroVideo(false);
            this.showHideRoomsSelector(true);
            EventsManager.dispatchEvent({ type: AppData.Events.playSound, data: 'button_01' });
        });

        // Room selector:
        this.roomsSelectorContainer = document.querySelector('#rooms-selector-container');
        this.roomsSelectorPanel = document.querySelector('#rooms-selector-panel');
        this.highlightedLineNameElement = document.querySelector('#highlighted-line-name');
        this.roomNameSubtitleElement = document.querySelector('#room-name-subtitle');

        // Room:
        this.roomNameParentElement = document.querySelector('#room-name-container');
        this.roomNameElement = document.querySelector('#room-name');

        // 3D viewer:
        this.productNameParentElement = document.querySelector('#product-name-container');
        this.productNameElement = document.querySelector('#product-name');

        this.descriptionElement = document.querySelector('.product-description');
        this.descriptionElementContainer = document.querySelector('.product-description-container');
        this.nameElement = document.querySelector('#nameText');
        this.descriptionTextElement = document.querySelector('#descriptionText');
        this.descriptionCloseElement = document.querySelector('#descriptionClose');
        this.descriptionCloseElement.addEventListener('click', () => {
            this.closeDescription();
            EventsManager.dispatchEvent({ type: AppData.Events.playSound, data: 'button_01' });
        });

        this.descriptionPrevious = document.querySelector('#descriptionPrevious');
        this.descriptionPrevious.addEventListener('click', () => {
            this.previousDetailPressed();
            EventsManager.dispatchEvent({ type: AppData.Events.playSound, data: 'button_01' });
        });
        this.descriptionNext = document.querySelector('#descriptionNext');
        this.descriptionNext.addEventListener('click', () => {
            this.nextDetailPressed();
            EventsManager.dispatchEvent({ type: AppData.Events.playSound, data: 'button_01' });
        });

        this.descriptionVideo = document.querySelector('#description-video');

        this.descriptionButtons = document.querySelector('#description-buttons');
        this.descriptionVideoButtons = document.querySelector('#description-video-buttons');

        this.descriptionGallery = document.querySelector('#description-gallery');
        this.descriptionGalleryPrevious = document.querySelector('#description-gallery-previous-image');
        this.descriptionGalleryCurrent = document.querySelector('#description-gallery-current-image');
        this.descriptionGalleryNext = document.querySelector('#description-gallery-next-image');
        this.descriptionGalleryPrevious.addEventListener('click', () => { /*this.previousGalleryImgPressed();*/ this.currentGalleryImgPressed(); });
        this.descriptionGalleryCurrent.addEventListener('click', () => { this.currentGalleryImgPressed(); });
        this.descriptionGalleryNext.addEventListener('click', () => { /*this.nextGalleryImgPressed();*/ this.currentGalleryImgPressed(); });

        // Gallery fullscreen image:
        this.fullscreenGalleryContainer = document.querySelector('#fullscreen-gallery-container');
        this.fullscreenGalleryImg = document.querySelector('#fullscreen-gallery-img');
        this.fullscreenGalleryCloseButton = document.querySelector('#fullscreen-gallery-close-button');
        this.fullscreenGalleryCloseButton.addEventListener('click', () => { this.closeFullscreenGalleryPressed(); });

        this.productPrevious = document.querySelector('#productPrevious');
        this.productPrevious.addEventListener('click', () => {
            this.previousProductPressed();
            EventsManager.dispatchEvent({ type: AppData.Events.playSound, data: 'button_01' });
        });
        this.productNext = document.querySelector('#productNext');
        this.productNext.addEventListener('click', () => {
            this.nextProductPressed();
            EventsManager.dispatchEvent({ type: AppData.Events.playSound, data: 'button_01' });
        });

        // Fullscreen video:
        this.fullscreenVideoContainer = document.querySelector('#fullscreen-video-container');
        this.fullscreenVideo = document.querySelector('#fullscreen-video');
        this.fullscreenVideoCloseButton = document.querySelector('#fullscreen-video-close-button');
        this.fullscreenVideoCloseButton.addEventListener('click', this.closeFullscreenVideoPressed.bind(this));

        /*document.querySelector('#contact-us-button');
        .addEventListener('click', () => {
            this.previousProductPressed();
        });*/

        // Menu:
        this.menuButton = document.querySelector('#menu-button');
        this.menuButton.addEventListener('click', () => {
            this.menuPressed();
        });

        // Menu buttons:
        this.menuBackToMachineButton = document.querySelector('#back-to-machines');
        this.menuBackToMachineButton.addEventListener('click', () => {
            // Go to Production Line selection. Home
            this.homePressed();
        });

        this.logoutMenuButton = document.querySelector('#menu-logout-button');
        this.logoutMenuButton.addEventListener('click', () => {
            this.homePressed(true);
            window.menuManager.backToLogin(false);
            // EventsManager.dispatchEvent({ type: AppData.Events.playSound, data: 'button_01' });
            location.reload();
        });

        this.closeMenuButton = document.querySelector('#menu-close-button');
        this.closeMenuButton.addEventListener('click', () => {
            this.closeMenu();
            EventsManager.dispatchEvent({ type: AppData.Events.playSound, data: 'menu_closed' });
        });

        this.menuVideoButtonsParent = document.querySelector('#menu-video-buttons-parent');
        this.menuLinkButtonsParent = document.querySelector('#menu-link-buttons-parent');

        //
        this.menuElement = document.querySelector('#menu-background');

        this.roomPanelElement = document.querySelector('#room-background-panel');
        this.roomImageElement = document.querySelector('#room-machine-image');
        this.roomPointsParentElement = document.querySelector('#room-panel');
    }

    start() {
        if (AppData.loggedUser.isAnonymous) {
            // this.editorBtn.parentNode.removeChild(this.editorBtn);
            this.editorBtn.remove();
            this.editorBtn.classList.add('hidden');
            this.editorBtn.style.display = "none";
        }
        else {
            this.editorBtn.classList.remove('hidden');
        }
    }

    showHideLoading(newValue = true) {
        // console.log('UIManager - showHideLoading - newValue', newValue);
        if (this.loadingScreenVisible === newValue) return;
        this.loadingScreenVisible = newValue;

        if (newValue) this.loadingContainerElement.classList.remove('hidden');
        else this.loadingContainerElement.classList.add('hidden');
    }

    showHideIntroVideo(newValue = true, callback = null) {
        if (callback && typeof callback === 'function') this.hideIntroVideoCallback = callback;
        console.log('UIManager - showHideIntroVideo - newValue', newValue);
        if (newValue) this.introVideoContainerElement.classList.remove('hidden');
        else { // Hide:
            this.introVideoContainerElement.classList.add('hidden');
            this.introVideoIframeElement.setAttribute('src', '');
            if (this.hideIntroVideoCallback && typeof this.hideIntroVideoCallback === 'function') {
                this.hideIntroVideoCallback.call();
                this.hideIntroVideoCallback = null;
            }
        }
    }

    // ------------- ROOM SELECTOR
    showHideRoomsSelectorOld(newValue = true) { // Old version
        /*
        if (newValue) {
            window.sceneManager.sceneIsReady = false;
            AppData.roomSelected = false;
            AppData.currentSelectedRoom = null;

            // Hide Product:
            this.hideProduct();
            // Hide Room:
            this.hideRoom();

            // Delete previous buttons:
            for (let i = this.roomsSelectorPanel.children.length - 1; i >= 0; i--) {
                let child = this.roomsSelectorPanel.children[i];
                child.parentNode.removeChild(child);
                child.remove();
            }
            // Create buttons:
            for (let i = 0; i < AppData.roomsFromDB.length; i++) {
                this.createRoomSelectorButton(i, AppData.roomsFromDB[i]);
            }
            // Show:
            this.showHideRoomBackground(true);
            setTimeout(() => {
                this.roomsSelectorContainer.classList.remove('hidden');
            }, 100);

            // Show popup:
            if (window.localStorage.getItem('welcome-popup-shown') !== 'true') {
                window.localStorage.setItem('welcome-popup-shown', 'true');
                this.showPopupMessage(
                    Localization.get('welcome_popup_title'),
                    Localization.get('welcome_popup_message')
                );
            }
        }
        else {
            this.roomsSelectorContainer.classList.add('hidden');
        }
        this.showHideHomeButton(!newValue);
        this.showHideMenuButton(!newValue);
        */
    }

    showHideRoomsSelector(newValue = true, autoSelectRoom = false) {
        // console.warn('UIManager - showHideRoomsSelector - newValue', newValue, ', autoSelectRoom:', autoSelectRoom);
        if (newValue) {
            window.sceneManager.sceneIsReady = false;
            AppData.roomSelected = false;
            AppData.currentSelectedRoom = null;

            this.showHideIntroBackground(false);
            this.showHideRoomBackground(false);
            this.showHideModelBackground(false);
            // Hide Product:
            this.hideProduct();
            // Hide Room:
            this.hideRoom();
            // Buttons:
            this.showHideHomeButton(false);
            this.showHideMenuButton(true);
            // REMOVE BUTTONS
            this.deletePreviousVideoButtons();
            this.deletePreviousLinkButtons();

            // Create:
            this.createRoomSelector();

            // Show:
            this.showHideRoomSelectorBackground(true);
            /// setTimeout(() => {
                this.swiper.enable();
                // this.swiper.el.classList.remove('hidden');
                this.roomsSelectorContainer.classList.remove('hidden');
                if (AppData.roomSelectedIdx) {
                    this.fromMachine = true;
                    this.swiper.slideTo(AppData.roomSelectedIdx, 1);
                    this.fromMachine = false;
                    if (autoSelectRoom) {
                        this.selectRoom(AppData.roomSelectedIdx, false);
                    }
                }
            /// }, 100);

            // Show intro video:
            if (window.localStorage.getItem('intro-video-viewed') !== 'true') {
                window.localStorage.setItem('intro-video-viewed', 'true');
                /// setTimeout(() => {
                    this.showHideRoomsSelector(false);
                /// }, 110);
                this.showHideIntroVideo(true, () => this.checkWelcomePopup());
            }
            else {
                // Show popup:
                this.checkWelcomePopup();
            }
        }
        else { // Hide Rooms Selector
            // this.swiper.classList.el.add('hidden');
            this.roomsSelectorContainer.classList.add('hidden');
            this.showHideRoomSelectorBackground(false);
            if (this.swiper) this.swiper.disable();
        }
    }

    checkWelcomePopup() {
        /*if (window.localStorage.getItem('welcome-popup-shown') !== 'true') {
            window.localStorage.setItem('welcome-popup-shown', 'true');
            this.showPopupMessage(
                Localization.get('welcome_popup_title'),
                Localization.get('welcome_popup_message')
            );
        }*/
    }

    createRoomSelector() {
        // TODO MOVE TO UI MANAGER:
        /* const swiper = new Swiper('.swiper', {
          // Optional parameters
          direction: 'horizontal',
          loop: true,

          // If we need pagination
          pagination: {
            el: '.swiper-pagination',
          },

          // Navigation arrows
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },

          // And if we need scrollbar
          //scrollbar: {
            //el: '.swiper-scrollbar',
          //},
        }); */
        const swiper = this.swiper = new Swiper(".mySwiper", {
            init: false,
            slidesPerView: 3,
            spaceBetween: 110,
            centeredSlides: true,
            // slideToClickedSlide: true,

            // If we need pagination
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },

            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
        // console.log('UIManager - createRoomSelector - swiper', swiper);
        swiper.init();
        swiper.removeAllSlides();
        this.swiper.disable();

        // Create slides swiper:
        for (let i = 0; i < AppData.roomsFromDB.length; i++) {
            // console.log('append', AppData.roomsFromDB[i].roomImagefile);
            swiper.appendSlide(
                `<div class="swiper-slide" role="group">
                <img src="${AppData.roomsFromDB[i].roomImagefile/*.replace('.png', '_selector.png')*/}">
                <img src="${AppData.roomsFromDB[i].roomImagefile.replace('.png', '_glow.png')}" class="room-image-glow">
                </div>`
            );
            // AppData.roomsFromDB[i].roomSelectorGlowImagefile
        }
        // Select first:
        this.highlightLine(0);

        // Events:
        swiper.on('slideChange', (swiper) => {
            // console.log('Slide changed', swiper.snapIndex, AppData.roomsFromDB[swiper.snapIndex]);
            this.highlightLine(swiper.snapIndex);
            if (!this.fromMachine) {
                const swipeSound = swiper.snapIndex > swiper.previousIndex ? 'swipe_right' : 'swipe_left';
                EventsManager.dispatchEvent({ type: AppData.Events.playSound, data: swipeSound });
            }
        });
        swiper.on('click', () => {
            // console.log('Slide clicked', swiper, ', clickedIndex === activeIndex?', swiper.clickedIndex === swiper.activeIndex);
            if (swiper.clickedIndex === swiper.activeIndex) {
                this.selectRoom(swiper.clickedIndex);
            }
            else {
                swiper.slideTo(swiper.clickedIndex);
            }
        });
        // this.updateUrlToLine(null);
    }

    highlightLine(index) {
        // console.log('UIManager - highlightLine - index', index);
        this.highlightedLineNameElement.innerHTML = AppData.roomsFromDB[index].roomName;
        if (index % 2 === 0) this.roomSelectorBg.classList.add('room-selector-background-inverted');
        else this.roomSelectorBg.classList.remove('room-selector-background-inverted');

        // Subtitle:
        if (AppData.roomsFromDB[index].roomSubtitle && AppData.roomsFromDB[index].roomSubtitle.length > 0) {
            const subtitleText = AppData.roomsFromDB[index].roomSubtitle.find((txt) => txt.lang === Localization.lan || txt.lang === 'all-languages') || null;
            if (subtitleText) this.roomNameSubtitleElement.innerHTML = subtitleText.localizedText;
        }
    }

    createRoomSelectorButton(roomId, roomData) {
        // console.log('createRoomSelectorButton() roomData:', roomData);
        const button = document.createElement('div');
        button.classList.add('rooms-selector-room');
        button.addEventListener('click', () => {
            this.selectRoom(roomId);
        });
        this.roomsSelectorPanel.appendChild(button);

        const image = document.createElement('div');
        image.classList.add('rooms-selector-room-img');
        image.style.content = `url("${roomData.roomImagefile}")`;
        //image.setAttribute('src', roomData.roomImagefile);
        button.appendChild(image);

        const text = document.createElement('div');
        text.classList.add('rooms-selector-title');
        text.innerHTML = roomData.roomName.toString();
        button.appendChild(text);

        // console.log('createRoomSelectorButton() - button', button);
        return button;
    }

    selectRoom(roomId, playsound = true) {
        if (AppData.roomSelected) return
        AppData.roomSelected = true;
        AppData.roomSelectedIdx = roomId;
        // console.log('UIManager - selectRoom: - roomId:', roomId);
        this.showHideRoomsSelector(false);
        // Buttons:
        this.showHideHomeButton(true);
        this.showHideMenuButton(true);
        EventsManager.dispatchEvent({ type: AppData.Events.getRoomProducts, data: roomId });
        AppData.currentSelectedRoom = AppData.roomsFromDB[roomId];
        // console.log('UIManager - selectRoom() - AppData.roomsFromDB[roomId]', AppData.currentSelectedRoom);
        gtag('event', 'machine_line_selected', { 'machine_line_name': AppData.currentSelectedRoom.roomName }); // Analytics
        this.updateUrlToLine(AppData.currentSelectedRoom);
        if (playsound) EventsManager.dispatchEvent({ type: AppData.Events.playSound, data: 'button_02' });
    }

    updateUrlToLine(selectedRoom) {
        // console.warn('updateUrlToLine() - window.location', window.location.href, ', selectedRoom:', selectedRoom);
        const url = new URL(window.location.href);
        const search_params = url.searchParams;
        if (selectedRoom) search_params.set('line', selectedRoom.roomName);
        else search_params.delete('line');
        url.search = search_params.toString();
        const new_url = url.toString();
        console.log('updateUrlToLine new_url:', new_url);

        const state = {};
        const title = '';
        history.replaceState(state, title, new_url);

        if (!selectedRoom) this.updateUrlToMachine(null); // Deselect machine
    }

    updateUrlToMachine(selectedMachine) {
        // console.warn('updateUrlToMachine() - window.location', window.location.href, ', selectedMachine:', selectedMachine);
        const url = new URL(window.location.href);
        const search_params = url.searchParams;
        if (selectedMachine) search_params.set('machine', selectedMachine.productName);
        else search_params.delete('machine');
        url.search = search_params.toString();
        const new_url = url.toString();
        console.log('updateUrlToMachine new_url:', new_url);

        const state = {};
        const title = '';
        history.replaceState(state, title, new_url);
    }
    // ------------- END ROOM SELECTOR

    showHideMenuButton(newValue = true) {
        if (newValue) this.menuButton.classList.remove('hidden');
        else this.menuButton.classList.add('hidden');
    }

    showHideHomeButton(newValue = true) {
        if (newValue) this.menuBackToMachineButton.classList.remove('hidden');
        else this.menuBackToMachineButton.classList.add('hidden');
    }

    previousProductPressed () {
        EventsManager.dispatchEvent({ type: AppData.Events.previousProductPressed });
    }

    nextProductPressed () {
        EventsManager.dispatchEvent({ type: AppData.Events.nextProductPressed });
    }

    previousDetailPressed () {
        EventsManager.dispatchEvent({ type: AppData.Events.previousDetailPressed });
    }

    nextDetailPressed () {
        EventsManager.dispatchEvent({ type: AppData.Events.nextDetailPressed });
    }

    menuPressed() {
        this.menuElement.classList.toggle('visible');
        const soundKey = this.menuElement.classList.contains('visible') ? 'menu_open' : 'menu_closed';
        EventsManager.dispatchEvent({ type: AppData.Events.playSound, data: soundKey });
    }

    homePressed(isLogout = false) {
        // this.showRoom(); /////////
        const autoSelectRoom = window.sceneManager.selectedModelData !== null && AppData.currentSelectedRoom.products.length > 1;
        this.showHideRoomsSelector(true, autoSelectRoom);
        this.closeMenu();
        this.closeFullscreenGalleryPressed();
        this.closeFullscreenVideoPressed();
        this.updateUrlToMachine(null); // Deselect machine
        if (!autoSelectRoom || isLogout) this.updateUrlToLine(null); // Deselect line
        EventsManager.dispatchEvent({ type: AppData.Events.playSound, data: 'menu_closed' });
    }

    closeMenu() {
        this.menuElement.classList.remove('visible');
    }

    initialize() {
    }

    showHideProductName(newValue = true) {
        if (this.productNameParentElement) {
            if (newValue) this.productNameParentElement.classList.remove('hidden');
            else this.productNameParentElement.classList.add('hidden');
        }
    }

    subscribeToEvents() {
        EventsManager.addEventListener(AppData.Events.onResize, this.onWindowResize.bind(this));

        EventsManager.addEventListener(AppData.Events.showDetailDescription, (eventData) => {
            if (eventData.data !== undefined) this.showDetailDescription(eventData.data);
        });

        EventsManager.addEventListener(AppData.Events.productLoaded, (eventData) => {
            if (eventData.data !== undefined) this.onProductLoaded(eventData.data);
        });

        EventsManager.addEventListener(AppData.Events.roomLoaded, this.onRoomLoaded.bind(this));

        EventsManager.addEventListener(AppData.Events.onLanguageChanged, () => {
            this.translateAll();
        });
    }

    translateAll() {
        if (this.currentDescriptionPoint) this.showDetailDescription(this.currentDescriptionPoint);
        this.createMenuButtons();
        if (this.swiper) this.highlightLine(this.swiper.snapIndex);
    }

    onWindowResize() {
        if (AppData.isLandscape()) this.rotateDeviceContainer.classList.add('hidden');
        else this.rotateDeviceContainer.classList.remove('hidden');
    }

    onRoomLoaded() {
        // console.log('UIManager - onRoomLoaded()');
        this.showRoom();
    }

    onProductLoaded(modelData) {
        this.productNameElement.innerHTML = modelData.productName;
        // Create DOM elements for the points:
        if (modelData.points) {
            /* console.log(
                'UIManager - onProductLoaded - window.sceneManager.selectedModelData.position:',
                window.sceneManager.selectedModelData.position.x,
                window.sceneManager.selectedModelData.position.y,
                window.sceneManager.selectedModelData.position.z
            ); */
            modelData.points.forEach((point) => {
                if (!point.dataIsAdjusted) {
                    // Adjust points positions to align with parent:
                    point.cameraPosition.x += window.sceneManager.selectedModelData.position.x;
                    point.cameraPosition.y += window.sceneManager.selectedModelData.position.y;
                    point.cameraPosition.z += window.sceneManager.selectedModelData.position.z;
                    point.cameraTarget.x += window.sceneManager.selectedModelData.position.x;
                    point.cameraTarget.y += window.sceneManager.selectedModelData.position.y;
                    point.cameraTarget.z += window.sceneManager.selectedModelData.position.z;
                    point.position.x += window.sceneManager.selectedModelData.position.x;
                    point.position.y += window.sceneManager.selectedModelData.position.y;
                    point.position.z += window.sceneManager.selectedModelData.position.z;
                    if (point.position2 && (point.position2.x || 0 && point.position2.y || 0 && point.position2.z || 0)) {
                        point.position2.x += window.sceneManager.selectedModelData.position.x;
                        point.position2.y += window.sceneManager.selectedModelData.position.y;
                        point.position2.z += window.sceneManager.selectedModelData.position.z;
                    }
                    point.dataIsAdjusted = true;
                }
            });
            this.createPoints(modelData.points);
        }
        this.showHideProductName(true);

        if (modelData.points && modelData.points.length > 1) {
            this.descriptionPrevious.classList.remove('hide-completely')
            this.descriptionNext.classList.remove('hide-completely')
        }
        else {
            this.descriptionPrevious.classList.add('hide-completely')
            this.descriptionNext.classList.add('hide-completely')
        }
    }

    showPopupMessage(title = '', content = '') {
        console.log('UIManager - showPopupMessage() - title:', title);
        this.popupTitleElement.innerHTML = title;
        this.popupContentElement.innerHTML = content;
        this.showHidePopup(true);
    }

    showHidePopup(newValue = true) {
        if (newValue) this.popupContainer.classList.remove('hidden');
        else this.popupContainer.classList.add('hidden');

        const soundKey = this.popupContainer.classList.contains('hidden') ? 'notification_off' : 'notification_on';
        EventsManager.dispatchEvent({ type: AppData.Events.playSound, data: soundKey });
    }

    createPoints(pointsData) {
        // console.log('createPoints() Before pointsData:', pointsData);
        for (let i = 0; i < pointsData.length; i++) {
            // console.log('pointsData[i]', pointsData[i].element);
            const newElement = this.createPoint(pointsData[i], document.body, null, pointsData[i].position);
            newElement.addEventListener('click', () => {
                this.clickDetail(pointsData[i]);
            });
            pointsData[i].element = newElement; // array?

            if (pointsData[i].position2 &&
                pointsData[i].position2.x || 0 && pointsData[i].position2.y || 0 && pointsData[i].position2.z || 0
            ) {
                const data2 = {...pointsData[i]};
                // console.log('UIManager - createPoints - data2', data2);
                const newElement2 = this.createPoint(data2, document.body, null, data2.position2);
                // console.log('UIManager - createPoints - newElement2', newElement2);
                newElement2.addEventListener('click', () => {
                    this.clickDetail(data2);
                });
                pointsData[i].element2 = newElement2; // array?
                data2.element2 = newElement2;
            }
        }
        // console.log('createPoints() After pointsData:', pointsData);
    }

    removePoints(pointsData) {
        // console.log('UIManager - removePoints() - pointsData:', pointsData);
        if (!pointsData) return;
        for (let i = 0; i < pointsData.length; i++) {
            // console.log('pointsData[i]', pointsData[i]);
            // console.log('.....UIManager - removePoints - pointsData[i].element', pointsData[i].element);
            pointsData[i].element.parentNode.removeChild(pointsData[i].element);
            pointsData[i].element.remove();
            pointsData[i].element = null;
            if (pointsData[i].element2) {
                pointsData[i].element2.parentNode.removeChild(pointsData[i].element2);
                pointsData[i].element2.remove();
                pointsData[i].element2 = null;
            }
        }
    }

    createPoint(pointData, parent = document.body, forcedLabelNumber = null, position = null) {
        if (position) pointData.position = position;
        const point = document.createElement('div');
        // console.log('UIManager - createPoint() - point:', point, ', pointData:', pointData, ', forcedLabelNumber:', forcedLabelNumber);
        point.classList.add('point');
        point.classList.add(`point-${pointData.id}`);

        const pointCircle = document.createElement('div');
        pointCircle.classList.add('point-circle');
        point.appendChild(pointCircle);

        const inner = document.createElement('div');
        inner.classList.add('point-circle-inner');
        inner.innerHTML = forcedLabelNumber !== null ? forcedLabelNumber : pointData.id.toString();
        // console.log('createPoint() - inner.innerHTML:', inner.innerHTML);
        pointCircle.appendChild(inner);

        const textBox = document.createElement('div');
        textBox.classList.add('point-text-box');
        point.appendChild(textBox);

        const text = document.createElement('div');
        text.classList.add('point-text');
        // console.log('UIManager - createPoint - pointData.hoverText', pointData.hoverText);
        if (typeof pointData.hoverText === 'string') text.innerHTML = pointData.hoverText;
        else {
            point.updateText = () => {
                const hoverTxt = pointData.hoverText.find((txt) => txt.lang === Localization.lan || txt.lang === 'all-languages');
                text.innerHTML = hoverTxt ? hoverTxt.localizedText : null;
            }
            point.updateText();
            EventsManager.addEventListener(AppData.Events.onLanguageChanged, () => {
                point.updateText();
            });
        }
        textBox.appendChild(text);

        // point.pointData = pointData;

        parent.appendChild(point);
        // console.log('createPoint() - point:', point);
        return point;
    }

    clickDetail(point) {
        console.log('clickDetail() point:', point);
        window.sceneManager.selectDetail(point);
        EventsManager.dispatchEvent({ type: AppData.Events.playSound, data: 'button_02' });
    }

    showDetailDescription(point) {
        // console.log('UIManager - showDetailDescription() - point:', point);
        this.currentDescriptionPoint = point;
        point.element.classList.add('point-main-color');
        if (point.element2) point.element2.classList.add('point-main-color');
        this.descriptionVisible = true;
        this.descriptionElement.scrollTo(0, 0); // Reset scroll to top
        this.descriptionElementContainer.classList.add('visible');
        this.showHideProductName(false);
        if (typeof point.hoverText === 'string') this.nameElement.innerHTML = point.hoverText;
        else {
            const hoverTxt = point.hoverText.find((txt) => txt.lang === Localization.lan || txt.lang === 'all-languages');
            this.nameElement.innerHTML = hoverTxt ? hoverTxt.localizedText : null;
        }
        let descriptionText = '';
        if (point.description.text && point.description.text.length > 0) {
            descriptionText = point.description.text.find((txt) => txt.lang === Localization.lan || txt.lang === 'all-languages') || null;
            if (descriptionText) descriptionText = descriptionText.localizedText;
        }
        // console.log('UIManager - showDetailDescription - descriptionText', descriptionText);
        this.descriptionTextElement.innerHTML = descriptionText;
        this.createDescriptionVideo(point);
        let descriptionButtons = null;
        if (point.description.buttons && point.description.buttons.length > 0) {
            descriptionButtons = point.description.buttons.find((btns) => btns.lang === Localization.lan || btns.lang === 'all-languages') || null;
            if (descriptionButtons) descriptionButtons = descriptionButtons.localizedButtons;
        }
        // console.log('UIManager - showDetailDescription - descriptionButtons', descriptionButtons);
        this.createDescriptionButtons(descriptionButtons);
        this.createDescriptionGallery(point);
        this.updateGallerySwiper(point);

        this.enableDisableMask(true);
    }

    createDescriptionVideo(point) {
        // OLD:
        /*
        if (this.descriptionVideosExtraElements.length > 0) {
            for (let i = 0; i < this.descriptionVideosExtraElements.length; i++) {
                this.descriptionVideosExtraElements[i].parentNode.removeChild(this.descriptionVideosExtraElements[i]);
                this.descriptionVideosExtraElements[i].remove();
            }
            this.descriptionVideosExtraElements = [];
        }

        if (point.description.video && point.description.video.length > 0 && point.description.video[0] !== '') {
            point.description.video.forEach((videoURL, idx) => {
                if (videoURL !== '') {
                    if (videoURL[videoURL.length - 1] === '/') videoURL = videoURL.slice(0, videoURL.length - 1);
                    if (videoURL.indexOf('?') === -1) videoURL += '?';
                    else videoURL += '&';
                    videoURL += 'modestbranding=1'; // Disable YouTube logo
                    videoURL += '&rel=0'; // Do not show related videos on playback
                    // videoURL += '&controls=0'; // Disable player controls
                    videoURL += '&disablekb=1'; // Disable keyboard control shortcuts
                    // console.log('createDescriptionVideo() - videoURL:', videoURL);

                    if (idx === 0) {
                        this.descriptionVideo.setAttribute('src', videoURL);
                        this.descriptionVideo.classList.remove('display-none');

                        setTimeout(() => {
                            console.log('this.descriptionVideo', this.descriptionVideo);
                            this.descriptionVideo.addEventListener('click', () => {
                                console.log('click - this.descriptionVideo', this.descriptionVideo);
                            });
                            this.descriptionVideo.addEventListener('onpointerdown', () => {
                                console.log('onpointerdown - this.descriptionVideo', this.descriptionVideo);
                            });
                            this.descriptionVideo.addEventListener('pointerdown', () => {
                                console.log('pointerdown - this.descriptionVideo', this.descriptionVideo);
                            });
                            this.descriptionVideo.addEventListener('onplay', () => {
                                console.log('onplay - this.descriptionVideo', this.descriptionVideo);
                            });
                        }, 500);
                    }
                    else {
                        this.descriptionVideosExtraElements[idx - 1] = this.descriptionVideo.cloneNode(true);
                        this.descriptionVideosExtraElements[idx - 1].removeAttribute("id");
                        this.descriptionVideo.parentNode.insertBefore(this.descriptionVideosExtraElements[idx - 1], this.descriptionVideo);
                        this.descriptionVideosExtraElements[idx - 1].setAttribute('src', videoURL);
                        this.descriptionVideosExtraElements[idx - 1].classList.remove('display-none');
                    }

                    // Hide YouTube player buttons
                    // setTimeout(() => {
                    //     console.log('UIManager - createDescriptionVideo - this.descriptionVideo.contentWindow.document', this.descriptionVideo.contentWindow.document);
                    //     const youtubeMuteButton = this.descriptionVideo.contentWindow.document.querySelector('.ytp-mute-button');
                    //     console.log('UIManager - createDescriptionVideo - youtubeMuteButton', youtubeMuteButton);
                    //     youtubeMuteButton.style.display = 'none';
                    // }, 100);
                }
            });
        }
        else {
            this.descriptionVideo.classList.add('display-none'); // Hide video
            this.stopDescriptionVideo(); // Stop video
        }
        */

        // NEW:
        // Delete previous video buttons:
        for (let i = this.descriptionVideoButtons.childElementCount - 1; i > 0; i--) {
            if (i !== 0) { // Don't remove first child, it's the template item
                this.descriptionVideoButtons.children[i].remove();
            }
        }
        // Create new buttons:
        if (point.description.video && point.description.video.length > 0 && point.description.video[0] !== '') {
            point.description.video.forEach((videoUrl, idx) => {
                const galleryBtn = this.descriptionVideoButtons.firstChild.cloneNode(true); // Clone template element
                galleryBtn.addEventListener('click', () => {
                    // Open video player
                    this.showHideFullscreenVideo(true, videoUrl);
                });
                galleryBtn.querySelector('.description-link-btn-text').innerHTML = `${Localization.get('video')} ${idx + 1}`;
                galleryBtn.style.display = null;
                this.descriptionVideoButtons.appendChild(galleryBtn);
            });

            if (this.descriptionVideoButtons.childElementCount > 1) this.descriptionVideoButtons.classList.remove('hide-completely');
        }
        else {
            //this.descriptionVideo.classList.add('display-none'); // Hide video
            //this.stopDescriptionVideo(); // Stop video
        }
    }

    createDescriptionButtons(buttons) {
        // Delete previous video buttons:
        for (let i = this.descriptionButtons.childElementCount - 1; i > 0; i--) {
            if (i !== 0) { // Don't remove first child, it's the template item
                this.descriptionButtons.children[i].remove();
            }
        }

        // console.log('createDescriptionButtons() buttons:', buttons);
        if (!buttons || buttons.length === 0) {
            this.descriptionButtons.classList.add('hide-completely')
        }
        else {
            for (let i = 0; i < buttons.length; i++) {
                console.log('UIManager - createDescriptionButtons - buttons[i]', buttons[i]);
                const btn = this.descriptionButtons.firstChild.cloneNode(true); // Clone template element
                btn.addEventListener('click', () => {
                    window.open(buttons[i].url, '_blank').focus();
                });
                btn.querySelector('.description-link-btn-text').innerHTML = buttons[i].text;
                btn.querySelector('.description-link-btn-image').setAttribute('src', buttons[i].buttonImage);
                btn.style.display = null;
                this.descriptionButtons.appendChild(btn);
            }
        }
        if (this.descriptionButtons.childElementCount > 1) this.descriptionButtons.classList.remove('hide-completely');
    }

    createDescriptionGallery(point) {
        // OLD:
        /* this.galleryImgIndex = 0;
        if (point.description.gallery && point.description.gallery.length > 0) {
            this.updateGalleryImages(point);
            this.descriptionGallery.classList.remove('display-none');
        }
        else {
            this.descriptionGallery.classList.add('display-none');
        } */

        // NEW:
        if (point.description.gallery && point.description.gallery.length > 0) {
            const galleryBtn = this.descriptionButtons.firstChild.cloneNode(true); // Clone template element
            galleryBtn.addEventListener('click', () => {
                this.currentGalleryImgPressed();
            });
            galleryBtn.querySelector('.description-link-btn-text').innerHTML = Localization.get('gallery');
            galleryBtn.querySelector('.description-link-btn-image').setAttribute('src', './images/ui/image_icon.png');
            galleryBtn.style.display = null;
            this.descriptionButtons.appendChild(galleryBtn);
        }
        if (this.descriptionButtons.childElementCount > 1) this.descriptionButtons.classList.remove('hide-completely');
    }

    stopDescriptionVideo() {
        // this.descriptionVideo.pause();
        // this.descriptionVideo.currentTime = 0;
        //this.descriptionVideo.setAttribute('src', '');
    }

    updateGalleryImages(point) {
        // Clear old image first:
        this.descriptionGalleryPrevious.setAttribute('src', '');
        this.descriptionGalleryCurrent.setAttribute('src', '');
        this.descriptionGalleryNext.setAttribute('src', '');
        // Load new images:
        this.descriptionGalleryPrevious.setAttribute('src', this.getGalleryThumbnailFile(point, this.getPreviousGalleryImgIndex()));
        this.descriptionGalleryCurrent.setAttribute('src', this.getGalleryThumbnailFile(point, this.galleryImgIndex));
        this.descriptionGalleryNext.setAttribute('src', this.getGalleryThumbnailFile(point, this.getNextGalleryImgIndex()));

        const prevImgAvailable = this.getPreviousGalleryImgIndex() != this.galleryImgIndex;
        const nextImgAvailable = this.getNextGalleryImgIndex() != this.galleryImgIndex; // TODO check nextId != prevId in case there are only 2 gallery images?
        if (prevImgAvailable) {
            this.descriptionGalleryPrevious.classList.remove('hide-completely');
        }
        else this.descriptionGalleryPrevious.classList.add('hide-completely');

        if (nextImgAvailable) {
            this.descriptionGalleryNext.classList.remove('hide-completely');
        }
        else this.descriptionGalleryNext.classList.add('hide-completely');

        if (!prevImgAvailable && !nextImgAvailable) {
            this.descriptionGalleryCurrent.classList.add('description-gallery-current-image-alone');
            this.descriptionGallery.classList.add('description-gallery-alone');
        }
        else {
            this.descriptionGalleryCurrent.classList.remove('description-gallery-current-image-alone');
            this.descriptionGallery.classList.remove('description-gallery-alone');
        }
    }

    getGalleryThumbnailFile(point, index) {
        const originalImageFile = this.getGalleryOriginalFile(point, index);
        const extension = originalImageFile.split('.').pop();
        return originalImageFile.replace(`.${extension}`, `_thumbnail.${extension}`);
    }

    getGalleryOriginalFile(point, index) {
        const originalImageFile = point.description.gallery[index];
        return originalImageFile;
    }

    previousGalleryImgPressed() {
        // console.log('UIManager - previousGalleryImgPressed()');
        this.galleryImgIndex = this.getPreviousGalleryImgIndex();
        this.updateGalleryImages(this.currentDescriptionPoint);
    }

    currentGalleryImgPressed() {
        console.log('UIManager - currentGalleryImgPressed()');
        // window.open(this.descriptionGalleryCurrent.getAttribute('src'));
        this.showHideFullscreenGallery(true, this.getGalleryThumbnailFile(this.currentDescriptionPoint, this.galleryImgIndex));
        setTimeout(() => {
            this.showHideFullscreenGallery(true, this.getGalleryOriginalFile(this.currentDescriptionPoint, this.galleryImgIndex), false);
        }, 100);
    }

    nextGalleryImgPressed() {
        // console.log('UIManager - nextGalleryImgPressed()');
        this.galleryImgIndex = this.getNextGalleryImgIndex();
        this.updateGalleryImages(this.currentDescriptionPoint);
    }

    getPreviousGalleryImgIndex() {
        let newIndex = this.galleryImgIndex;
        newIndex--;
        if (newIndex < 0) {
            newIndex = this.currentDescriptionPoint.description.gallery.length - 1;
        }
        // console.log('UIManager - getPreviousGalleryImgIndex() - newIndex:', newIndex);
        return newIndex;
    }

    getNextGalleryImgIndex() {
        let newIndex = this.galleryImgIndex;
        newIndex++;
        if (newIndex > this.currentDescriptionPoint.description.gallery.length - 1) {
            newIndex = 0;
        }
        // console.log('UIManager - getNextGalleryImgIndex() - newIndex:', newIndex);
        return newIndex;
    }

    showHideFullscreenGallery(newValue = true, imagePath = '', playSound = true) {
        if (newValue) {
            this.fullscreenGalleryContainer.classList.remove('hidden');
            this.gallerySwiper.enable();
            this.gallerySwiper.slideTo(0, 1);
        }
        else this.fullscreenGalleryContainer.classList.add('hidden');

        if (imagePath && imagePath.length > 0 && this.fullscreenGalleryImg) this.fullscreenGalleryImg.setAttribute('src', imagePath);
        if (playSound) {
            const soundKey = this.fullscreenGalleryContainer.classList.contains('hidden') ? 'notification_off' : 'notification_on';
            EventsManager.dispatchEvent({ type: AppData.Events.playSound, data: soundKey });
        }
    }

    closeFullscreenGalleryPressed() {
        // console.log('closeFullscreenGalleryPressed()');
        this.showHideFullscreenGallery(false);
    }

    createGallerySwiper() {
        const swiper = this.gallerySwiper = new Swiper(".mySwiper-gallery", {
            init: false,
            slidesPerView: 2,
            spaceBetween: 1,
            centeredSlides: true,
            slideToClickedSlide: false,

            // If we need pagination
            pagination: {
                el: ".swiper-pagination-gallery",
                clickable: true,
            },

            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next-gallery',
                prevEl: '.swiper-button-prev-gallery',
            },
        });
        // console.log('UIManager - createRoomSelector - swiper', swiper);
        swiper.init();
        swiper.removeAllSlides();
        swiper.disable();

        // Events:
        swiper.on('click', () => {
            // console.log('Slide clicked', swiper, ', clickedIndex === activeIndex?', swiper.clickedIndex === swiper.activeIndex);
            if (swiper.clickedIndex === swiper.activeIndex) {
                // Open image url in new tab?
                // if (swiper.slides[swiper.clickedIndex].slideImagePath) window.open(swiper.slides[swiper.clickedIndex].slideImagePath, '_blank').focus();
            }
            else {
                swiper.slideTo(swiper.clickedIndex);
            }
        });
        swiper.on('slideChange', (swiper) => {
            // console.log('Slide changed swiper:', swiper, swiper.snapIndex);
            const swipeSound = swiper.snapIndex > swiper.previousIndex ? 'swipe_right' : 'swipe_left';
            EventsManager.dispatchEvent({ type: AppData.Events.playSound, data: swipeSound });
        });
    }

    updateGallerySwiper(point) {
        this.gallerySwiper.removeAllSlides();
        this.gallerySwiper.disable();
        if (!point.description.gallery || point.description.gallery.length === 0) return;

        // Create slides swiper:
        for (let i = 0; i < point.description.gallery.length; i++) {
            // console.log('updateGallerySwiper append', this.getGalleryOriginalFile(point, i));
            this.gallerySwiper.appendSlide(
                `<div class="swiper-slide" role="group">
                <img src="${this.getGalleryOriginalFile(point, i)}">
                </div>`
            );
            this.gallerySwiper.slides[this.gallerySwiper.slides.length - 1].slideImagePath = this.getGalleryOriginalFile(point, i);
        }
        // Select first:
        this.gallerySwiper.slideTo(0);
    }

    closeDescription() {
        this.descriptionVisible = false;
        this.descriptionElementContainer.classList.remove('visible');
        if (window.sceneManager.sceneIsReady) this.showHideProductName(true);
        if (window.sceneManager.selectedModelData && window.sceneManager.selectedModelData.points) {
            for (let i = 0; i < window.sceneManager.selectedModelData.points.length; i++) {
                if (window.sceneManager.selectedModelData.points[i].element) {
                    window.sceneManager.selectedModelData.points[i].element.classList.remove('point-main-color');
                }
                if (window.sceneManager.selectedModelData.points[i].element2) {
                    window.sceneManager.selectedModelData.points[i].element2.classList.remove('point-main-color');
                }
            }
        }
        this.currentDescriptionPoint = null;

        this.stopDescriptionVideo();
        this.stopFullscreenVideo();

        // Zoom out event, send point:
        EventsManager.dispatchEvent({ type: AppData.Events.cameraZoomOut });

        this.enableDisableMask(false);
    }

    enableDisableMask(newStatus) {
        return;
        // maskPass.material.uniforms['str'].value
        gsap.to(window.sceneManager.maskPass.material.uniforms['str'], {
            duration: 0.5,
            value: newStatus ? 1 : 0,
        });
    }

    updatePointsPosition(points, raycaster) {
        if (!points || points.length == 0) return;
        // console.log('UIManager - updatePointsPosition() - points:', points.length);

        let vectorPosition;
        let screenPosition;
        let isOutsideScreen;
        let translateX;
        let translateY;
        let pointDistance;

        // Code to update points every X frames, to optimize performance. Changing multiple element.style in one frame can hurt performance
        /* if (this.updateTime === undefined) this.updateTime = 0;
        this.updateTime += 1;
        if (this.updateTime > 1) this.updateTime = 0;
        if (this.updateTime != 0) return; */

        for (const point of points) {
            if (!point.element) break;

            vectorPosition = new THREE.Vector3(point.position.x, point.position.y, point.position.z);
            /* const vectorPosition = new THREE.Vector3(
                point.position.x + window.sceneManager.selectedModelData.position.x,
                point.position.y + window.sceneManager.selectedModelData.position.y,
                point.position.z + window.sceneManager.selectedModelData.position.z
            ); */
            screenPosition = vectorPosition.clone();
            screenPosition.project(AppData.cameraManager.camera); // Project position to camera. Normalized from the center
            /*screenPosition = new THREE.Vector3(
                point.position.x * 0.1 * AppData.cameraManager.camera.position.x,
                point.position.y * 0.1 * AppData.cameraManager.camera.position.y,
                point.position.z * 0.1 * AppData.cameraManager.camera.position.z
            );*/
            //screenPosition.y += Math.random() * 0.1;

            isOutsideScreen = Math.abs(screenPosition.x) > 1.02 || Math.abs(screenPosition.y) > 1.02 || screenPosition.z >= 1;
            point.element.style.display = isOutsideScreen ? 'none' : 'unset';

            if (!isOutsideScreen) {
                translateX = screenPosition.x * (AppData.sizes.width * 0.5);
                translateY = screenPosition.y * (AppData.sizes.height * -0.5);
                point.element.style.transform = `translate(${translateX}px, ${translateY}px)`;
                // Show/Hide point:
                //raycaster.setFromCamera(screenPosition, AppData.cameraManager.camera);
                //const intersects = raycaster.intersectObjects(this.scene.children, true);
                pointDistance = vectorPosition.distanceTo(AppData.cameraManager.camera.position);
                point.element.style.zIndex = 1000 - Math.round(pointDistance * 10);
                if (this.descriptionVisible && point.id !== this.currentDescriptionPoint.id) {
                    point.element.classList.remove('visible');
                }
                else {
                    /*if (intersects.length > 0) {
                        // We only need to check the first element because it's the closest one:
                        const intersectionDistance = intersects[0].distance;
                        if (intersectionDistance < pointDistance) {
                            // There is something in front of the point
                            point.element.classList.remove('visible');
                        }
                        else {
                            // The point is closer
                            point.element.classList.add('visible');
                        }
                        // console.log('intersects:', intersects, 'intersectionDistance:', intersectionDistance);
                    }
                    else { // No intersections
                        point.element.classList.add('visible');
                    }*/
                    point.element.classList.add('visible'); // Version without raycast
                }
            }

            /////////////// This is a quick patch. Add elements to array and iterate if more points are needed in the future /////////
            if (point.element2) {
                vectorPosition = new THREE.Vector3(point.position2.x, point.position2.y, point.position2.z);
                /* const vectorPosition = new THREE.Vector3(
                    point.position2.x + window.sceneManager.selectedModelData.position.x,
                    point.position2.y + window.sceneManager.selectedModelData.position.y,
                    point.position2.z + window.sceneManager.selectedModelData.position.z
                ); */
                screenPosition = vectorPosition.clone();
                screenPosition.project(AppData.cameraManager.camera); // Project position to camera. Normalized from the center

                isOutsideScreen = Math.abs(screenPosition.x) > 1.02 || Math.abs(screenPosition.y) > 1.02 || screenPosition.z >= 1;
                point.element2.style.display = isOutsideScreen ? 'none' : 'unset';

                if (!isOutsideScreen) {
                    translateX = screenPosition.x * (AppData.sizes.width * 0.5);
                    translateY = screenPosition.y * (AppData.sizes.height * -0.5);
                    point.element2.style.transform = `translate(${translateX}px, ${translateY}px)`;
                    // Show/Hide point:
                    //raycaster.setFromCamera(screenPosition, AppData.cameraManager.camera);
                    //const intersects = raycaster.intersectObjects(this.scene.children, true);
                    pointDistance = vectorPosition.distanceTo(AppData.cameraManager.camera.position);
                    point.element2.style.zIndex = 1000 - Math.round(pointDistance * 10);
                    if (this.descriptionVisible && point.id !== this.currentDescriptionPoint.id) {
                        point.element2.classList.remove('visible');
                    }
                    else {
                        /*if (intersects.length > 0) {
                            // We only need to check the first element2 because it's the closest one:
                            const intersectionDistance = intersects[0].distance;
                            if (intersectionDistance < pointDistance) {
                                // There is something in front of the point
                                point.element2.classList.remove('visible');
                            }
                            else {
                                // The point is closer
                                point.element2.classList.add('visible');
                            }
                            // console.log('intersects:', intersects, 'intersectionDistance:', intersectionDistance);
                        }
                        else { // No intersections
                            point.element2.classList.add('visible');
                        }*/
                        point.element2.classList.add('visible'); // Version without raycast
                    }
                }
            }
            ///////////////
        }
    }

    showRoom() {
        // console.log('showRoom() AppData.roomFromDB:', AppData.roomFromDB);
        window.sceneManager.sceneIsReady = false;
        // Background:
        this.roomImageElement.setAttribute('src', AppData.roomFromDB.roomImagefile);

        // Room name text:
        this.roomNameElement.innerHTML = AppData.roomFromDB.roomName;

        // Product:
        this.hideProduct();

        // this.showHideMenuButton(false);

        // Remove points:
        this.removeRoomPoints();
        // Create points:
        this.createRoomImagePoints();

        // Show:
        this.roomPanelElement.classList.remove('hidden');
        this.showHideRoomBackground(true);

        // Select product automatically if there is only one point:
        if (this.roomPoints.length === 1) {
            this.clickRoomPoint(this.firstPointData);
        }
    }

    createRoomImagePoints() {
        if (!AppData.roomFromDB.products) return;
        this.firstPointData = null;
        for (let i = 0; i < AppData.roomFromDB.products.length; i++) {
            const idx = i;
            const product = AppData.roomFromDB.products[idx];

            const pointData = {
                id: idx,
                hoverText: product.productName,
                position: product.productPointPosition, //
            }
            this.roomPointsDatas.push(pointData);
            const newElement = this.createPoint(pointData, this.roomPointsParentElement, idx + 1);
            pointData.buttonElement = newElement;
            newElement.style.left = `${product.productPointPosition.x}%`;
            newElement.style.top = `${product.productPointPosition.y}%`;
            newElement.classList.add('visible');
            newElement.addEventListener('click', () => {
                this.clickRoomPoint(pointData);
            });
            this.roomPoints.push(newElement);

            // Save reference:
            if (this.firstPointData === null) this.firstPointData = pointData;
        }
    }

    hideRoom() {
        // console.log('hideRoom() AppData.roomFromDB:', AppData.roomFromDB);
        // Background:
        // this.roomImageElement.setAttribute('src', AppData.roomFromDB.roomImagefile);

        // Remove points:
        this.removeRoomPoints();
        this.roomButtons = [];

        // Hide:
        this.roomPanelElement.classList.add('hidden');
        this.showHideRoomBackground(false);
    }

    hideProduct() {
        // Hide product name:
        this.showHideProductName(false);
        // Remove model:
        window.sceneManager.removeCurrentModel();
    }

    removeRoomPoints() {
        for (let i = 0; i < this.roomPoints.length; i++) {
            this.roomPoints[i].parentNode.removeChild(this.roomPoints[i]);
            this.roomPoints[i].remove();
            this.roomPoints[i] = null;
        }
        this.roomPoints = [];
    }

    clickRoomPoint(pointData) {
        console.log('clickRoomPoint() pointData:', pointData);
        EventsManager.dispatchEvent({ type: AppData.Events.productSelected, data: pointData });
        this.showHideIntroBackground(false);
        this.showHideRoomBackground(false);
        this.showHideModelBackground(true);
        this.showHideMenuButton(true);
        this.createMenuButtons();
        this.createGallerySwiper();
        this.hideRoom();
    }

    showHideIntroBackground(newStatus) {
        if (newStatus) this.introBg.classList.remove('hidden');
        else this.introBg.classList.add('hidden');
    }

    showHideRoomSelectorBackground(newStatus) {
        if (newStatus) this.roomSelectorBg.classList.remove('hidden');
        else this.roomSelectorBg.classList.add('hidden');
    }

    showHideRoomBackground(newStatus) {
        if (newStatus) this.roomBg.classList.remove('hidden');
        else this.roomBg.classList.add('hidden');
    }

    showHideModelBackground(newStatus) {
        if (newStatus) this.modelBg.classList.remove('hidden');
        else this.modelBg.classList.add('hidden');
    }

    createMenuButtons() {
        // console.log('UIManager - createMenuButtons() - window.sceneManager.selectedModelData', window.sceneManager.selectedModelData);
        if (!window.sceneManager.selectedModelData) return;
        // Delete previous video buttons:
        this.deletePreviousVideoButtons();
        // Create video buttons:
        if (window.sceneManager.selectedModelData.productVideos && window.sceneManager.selectedModelData.productVideos.length > 0) {
            const productVideosData = window.sceneManager.selectedModelData.productVideos.filter(
                (data) => data.lang === Localization.lan || data.lang === 'all-languages'
            );
            for (let i = 0; i < productVideosData.length; i++) {
                const videoData = productVideosData[i];
                // console.log('UIManager - createMenuButtons() - videoData', videoData);
                const btn = this.menuVideoButtonsParent.firstChild.cloneNode(true);
                btn.querySelector('.menu-video-btn-text').innerHTML = Localization.get('video');
                btn.addEventListener('click', () => {
                    // Open video player
                    this.showHideFullscreenVideo(true, videoData.videoUrl);
                });
                btn.style.display = null;
                this.menuVideoButtonsParent.appendChild(btn);
            }
        }

        // Delete previous link buttons:
        this.deletePreviousLinkButtons();
        // Create link buttons:
        if (window.sceneManager.selectedModelData.productLinks && window.sceneManager.selectedModelData.productLinks.length > 0) {
            const productLinksData = window.sceneManager.selectedModelData.productLinks.filter(
                (data) => data.lang === Localization.lan || data.lang === 'all-languages'
            );
            for (let i = 0; i < productLinksData.length; i++) {
                const linkData = productLinksData[i];
                // console.log('UIManager - createMenuButtons() - linkData', linkData);
                for (let j = 0; j < linkData.localizedButtons.length; j++) {
                    const btnData = linkData.localizedButtons[j];
                    const btn = this.menuLinkButtonsParent.firstChild.cloneNode(true);
                    btn.querySelector('.menu-link-btn-image').setAttribute('src', btnData.buttonImage);
                    btn.querySelector('.menu-link-btn-text').innerHTML = btnData.text;
                    btn.addEventListener('click', () => {
                        window.open(btnData.url, '_blank').focus();
                    });
                    btn.style.display = null;
                    this.menuLinkButtonsParent.appendChild(btn);
                }
            }
        }
    }

    deletePreviousVideoButtons() {
        for (let i = this.menuVideoButtonsParent.childElementCount - 1; i > 0; i--) {
            if (i !== 0) { // Don't remove first child, it's the template item
                this.menuVideoButtonsParent.children[i].remove();
            }
        }
    }

    deletePreviousLinkButtons() {
        for (let i = this.menuLinkButtonsParent.childElementCount - 1; i > 0; i--) {
            if (i !== 0) { // Don't remove first child, it's the template item
                this.menuLinkButtonsParent.children[i].remove();
            }
        }
    }

    showHideFullscreenVideo(newValue = true, imagePath = '') {
        if (newValue) this.fullscreenVideoContainer.classList.remove('hidden');
        else {
            this.fullscreenVideoContainer.classList.add('hidden');
            this.stopFullscreenVideo();
        }

        if (imagePath && imagePath.length > 0) this.fullscreenVideo.setAttribute('src', imagePath);
        const soundKey = this.fullscreenVideoContainer.classList.contains('hidden') ? 'notification_off' : 'notification_on';
        EventsManager.dispatchEvent({ type: AppData.Events.playSound, data: soundKey });
    }

    closeFullscreenVideoPressed() {
        // console.log('closeFullscreenVideoPressed()');
        this.showHideFullscreenVideo(false);
    }

    stopFullscreenVideo() {
        // this.fullscreenVideo.pause();
        // this.fullscreenVideo.currentTime = 0;
        this.fullscreenVideo.setAttribute('src', '');
    }
}
