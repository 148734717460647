import * as THREE from 'three';

export default class AppData {
    constructor() {
      throw new Error('AbstractClassError');
    }

    static init(scene) {
        this.scene = scene;
    }

    static isPortrait() {
        // console.warn('AppData - isPortrait():', AppData.sizes.height > AppData.sizes.width);
        return AppData.sizes.height > AppData.sizes.width;
    }
    static isLandscape() {
        // console.warn('AppData - isLandscape:()', AppData.sizes.height < AppData.sizes.width);
        return AppData.sizes.height < AppData.sizes.width;
    }

    static LP(a, b) {
        if (AppData.isLandscape()) return a;
        return b;
    }

    static GetAspectRatio(invertOrientation) {
        let aspectRatio = 1;
        if (invertOrientation) {
            aspectRatio = AppData.LP(
                AppData.sizes.width / AppData.sizes.height,
                AppData.sizes.height / AppData.sizes.width
              );
        }
        else {
            aspectRatio = AppData.LP(
            AppData.sizes.height / AppData.sizes.width,
            AppData.sizes.width / AppData.sizes.height
            );
        }
        // console.log('GameData - GetAspectRatio() - aspectRatio:', aspectRatio);
        return aspectRatio;
    }

    static isMobile() {
        // return false; // Dev test
        return /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    static LoadJson(jsonPath, callback) {
        const jsonRequest = new XMLHttpRequest();
        jsonRequest.overrideMimeType("application/json");
        jsonRequest.open('GET', jsonPath, true);
        jsonRequest.onreadystatechange = function () {
            if (jsonRequest.readyState == 4) {
                if (jsonRequest.status == "200") {
                    // Required use of an anonymous callback as .open will NOT return a value but simply returns undefined in asynchronous mode
                    callback(jsonRequest.responseText);
                }
                else {
                    console.warn(jsonRequest);
                }
            }
        };
        jsonRequest.send(null);
    }
}

// Config:
AppData.jsonPath = './json/products.json';

// Flow variables:
AppData.loggedUser = null;
AppData.roomsFromDB = {};
AppData.roomFromDB = null;
AppData.roomSelected = false;
AppData.roomSelectedIdx = null;
AppData.currentSelectedRoom = null;
AppData.productsFromDB = [];

// Params:
const querystring = window.location.search; // Get querystring
const params = new URLSearchParams(querystring); // Create URLSearchParams from querystring
AppData.enableFpsMeter = params.get('enableFpsMeter') === 'true' || params.get('enableFpsMeter') === '1';

//////// Events: ////////
AppData.Events = Object.freeze({
    onResize: 'onResize',
    cameraZoomIn: 'cameraZoomIn',
    cameraZoomOut: 'cameraZoomOut',
    showDetailDescription: 'showDetailDescription',
    productLoaded: 'productLoaded',
    previousProductPressed: 'previousProductPressed',
    nextProductPressed: 'nextProductPressed',
    previousDetailPressed: 'previousDetailPressed',
    nextDetailPressed: 'nextDetailPressed',
    productSelected: 'productSelected',
    getRoomProducts: 'getRoomProducts',
    roomLoaded: 'roomLoaded',
    onLanguageChanged: 'onLanguageChanged',
    playSound: 'sound'
});

AppData.Sounds = Object.freeze({
    button_01: { volume: 0.3 },
    button_02: { volume: 0.3 },
    menu_closed: { volume: 0.3 },
    menu_open: { volume: 0.3 },
    notification_on: { volume: 0.3 },
    notification_off: { volume: 0.3 },
    swipe_left: { volume: 0.15 },
    swipe_right: { volume: 0.15 },
});

//////// Variables: ////////
AppData.envMapIntensity = 1.5;
AppData.cameraMinDistance = 3;
AppData.cameraMaxDistance = 9;

//////// Data: ////////
AppData.sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}
