import Firebase from './utils/firebase/firebase';
import AppData from './Managers/appData';
import EventsManager from './Managers/events-manager';
import Localization from './utils/localization/localization';

export default class FirebaseInterface {
  constructor() {
    window.firebaseInterface = this;
    this.firebase = new Firebase();
    this.enterExperienceBtn;
    this.isBusyLoging = false;
    this.isLoged = false;

    this.subscribeToEvents()
    this.getElements();
    this.initializeFirebase();
    this.firebase.signout();

    document.getElementById("loginBtn").onclick = () => {
      EventsManager.dispatchEvent({ type: AppData.Events.playSound, data: 'button_02' });
      this.doLogin(); // Login button on click listener
    }
    const passInput = document.getElementById("pass");
    if (passInput) {
      passInput.addEventListener("keyup", (event) => {
        // Number 13 is the "Enter" key on the keyboard
        if (event.keyCode === 13) {
          // Cancel the default action, if needed
          event.preventDefault();
          // Trigger the login function
          this.doLogin();
        }
      });
    }
  }

  subscribeToEvents() {
    EventsManager.addEventListener(AppData.Events.getRoomProducts, (eventData) => {
      if (eventData.data !== undefined) this.getRoomProducts(eventData.data);
    });
  }

  /* FIREBASE FUNCTIONALITY */
  initializeFirebase() {
    this.firebase.initialize();
    console.log('FirebaseInterface - initializeFirebase() - this.firebase:', this.firebase);
  }

  getElements() {
    this.enterExperienceBtn = document.querySelector('#enterExperienceBtn');
    this.enterExperienceBtn.addEventListener('click', () => {
      if (this.isBusyLoging || this.isLoged) return;
      // Clear URL params
      this.clearLineMachineParams();
      // Enter as guest without login
      this.doLoginAnonymously();
      EventsManager.dispatchEvent({ type: AppData.Events.playSound, data: 'button_02' });
    });
  }

  clearLineMachineParams() {
    const url = new URL(window.location.href);
    console.log('FirebaseInterface - clearLineMachineParams() - url', url);
    const search_params = url.searchParams;
    search_params.delete('line');
    search_params.delete('machine');
    url.search = search_params.toString();
    const new_url = url.toString();
    const state = {};
    const title = '';
    history.replaceState(state, title, new_url);
  }

  getProducts() {
    this.firebase.readFromPath('rooms')
      .then((response) => {
        console.log('Received data: ', response.val());

        const receivedObject = response.val();
        AppData.roomsFromDB = this.convertToArrayObject(receivedObject);
        if (AppData.loggedUser.isAnonymous) { 
          let filteredRooms = AppData.roomsFromDB.filter(room => room.roomStatus === 'Public');
          AppData.roomsFromDB = [...filteredRooms];
        }
        // console.log('getProducts() AppData.roomsFromDB:', AppData.roomsFromDB);
        console.table(AppData.roomsFromDB);

        this.onProductsLoaded();
      })
      .catch((error) => {
        console.error(error);
        this.onProductsLoadError();
      });
  }

  convertToArrayObject(firebaseObj) {
    let roomsArray = Object.values(firebaseObj);

    if (roomsArray != null && roomsArray.length > 0) {
      roomsArray.forEach((room) => {
        let productsArray = Object.values(room);

        if (productsArray != null && productsArray.length > 0) {
          productsArray.forEach((product) => {
            if (product.points != null) {
              product.points = Object.values(product.points);

              if (product.points.length > 0) {
                product.points.forEach((point) => {
                  if (point.description != null) {
                    if (point.description.gallery != null) point.description.gallery = Object.values(point.description.gallery);
                    if (point.description.buttons != null) point.description.buttons = Object.values(point.description.buttons);
                  }
                });
              }
            }
          });
        }
      });
    }
    // console.log('convertToArrayObject() roomsArray:', roomsArray);
    return roomsArray;
  }
  /* END FIREBASE FUNCTIONALITY */

  getRoomFromQuery() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('room');
  }

  getRoomProducts(roomId) {
    // console.log('firebase-interface getRoomProducts() - roomId:', roomId);
    if (AppData.roomsFromDB.length > 0) {
      AppData.productsFromDB = AppData.roomsFromDB[roomId].products;
      AppData.roomFromDB = AppData.roomsFromDB[roomId]; // Juan: I need to store room data
      // console.log('getRoomProducts() - AppData.roomFromDB', AppData.roomFromDB);
      const roomFromQuery = this.getRoomFromQuery();
      if (roomFromQuery) {
        // console.log('getRoomProducts() - roomId:', roomId, '- room from params query:', roomFromQuery);
        AppData.roomsFromDB.forEach((room) => {
          // console.log('AppData.roomsFromDB.forEach - room:', room);
          if (room.roomName == roomFromQuery)	{
            AppData.productsFromDB = room.products;
            AppData.roomFromDB = room; // Juan: I need to store room data
          }
        });
      }
      // Send room loaded event
      EventsManager.dispatchEvent({ type: AppData.Events.roomLoaded });
    }
  }

  /* Listener Functions */
  onProductsLoaded() {
    console.warn('Products loaded correctly. Products array available on "productsFromDB"');
    //this.getRoomProducts();
    window.sceneCallback();
  }

  onProductsLoadError() {
    console.warn('Error loading products');
    alert("Failed to retrieve products");
  }

  onLoginSuccess() {
    console.warn('Login Success');
    this.isLoged = true;
    window.menuManager.setLoginButtonText(Localization.get("logged-in"));
    window.menuManager.showHideLogin(false);
    window.menuManager.showHideEnterExperience(false);
  }

  onLoginError() {
    window.menuManager.setLoginButtonText(Localization.get("login"));
    console.warn('Login error');
    const loginContainer = document.getElementById("login-div");
    setTimeout(() => {
      loginContainer.classList.add("shake");
    }, 1);
  }
  /* End Listener Functions */

  doLogin() {
    if (this.isBusyLoging || this.isLoged) return;
    this.isBusyLoging = true;
    // Remove anim class
    const loginContainer = document.getElementById("login-div");
    // console.log(loginContainer.classList)
    loginContainer.classList.remove("shake");

    const emailTyped = document.getElementById("user");
    const passwordTyped = document.getElementById("pass");
    window.menuManager.setLoginButtonText(Localization.get("login..."));

    console.warn('FirebaseInterface - doLogin() - this.firebase:', this.firebase);
    this.firebase.loginWithEmailAndPassword(emailTyped.value, passwordTyped.value)
      .then((result) => {
        // console.log('loginWithEmailAndPassword - result:', result);
        AppData.loggedUser = result.user;
        this.onLoginSuccess();
        this.getProducts();
        this.isBusyLoging = false;
      })
      .catch((error) => {
        console.error("[Firebase] Error login user. Code: ", error.code, " - Message: ", error.message);
        this.onLoginError();
        this.isBusyLoging = false;
      });
  }

  doLoginAnonymously(callback = null) {
    console.log('FirebaseInterface - doLoginAnonymously() - callback:', callback);
    if (this.isBusyLoging || this.isLoged) return;
    this.enterExperienceBtn.setAttribute('localization-key', 'entering'); // Update localization key
    this.enterExperienceBtn.innerHTML = Localization.get(this.enterExperienceBtn.getAttribute('localization-key')); // Update localized text
    this.isBusyLoging = true;
    this.firebase.loginAnonymously()
      .then((result) => {
        // console.log('doLoginAnonymously - result:', result);
        AppData.loggedUser = result.user;
        this.onLoginSuccess();
        this.getProducts();
        if (callback && typeof callback === 'function') callback.call();
        this.isBusyLoging = false;
      })
      .catch((error) => {
        console.error("[Firebase] Error login user. Code: ", error.code, " - Message: ", error.message);
        this.onLoginError();
        this.isBusyLoging = false;
      });
  }

  resetLogin() {
    this.isLoged = false;
    this.isBusyLoging = false;
    this.enterExperienceBtn.setAttribute('localization-key', 'enter_experience'); // Update localization key
    this.enterExperienceBtn.innerHTML = Localization.get(this.enterExperienceBtn.getAttribute('localization-key')); // Update localized text
    window.menuManager.setLoginButtonText(Localization.get('login'));
  }
}
