import * as THREE from 'three';
import AppData from './appData';
import EventsManager from './events-manager';

export default class SoundManager {
  constructor(scene, renderer, canvas) {
    AppData.soundManager = this; // Save reference

    this.scene = scene;
    this.renderer = renderer;
    this.canvas = canvas;
    this.audioLoader = new THREE.AudioLoader();
    this.sounds = null;

    this.initialize();
    this.subscribeToEvents();
  }

  initialize() {
    this.sounds = {};

    for (const soundKey in AppData.Sounds) {
      const sound = new THREE.Audio(AppData.cameraManager.listener);
      this.audioLoader.load(`./sounds/${soundKey}.ogg`, (buffer) => {
        sound.setBuffer(buffer);
        sound.setVolume(AppData.Sounds[soundKey].volume);
      });

      this.sounds[soundKey] = sound;
    }
  }

  subscribeToEvents() {
    EventsManager.addEventListener(AppData.Events.playSound, (soundKey) => {
      const sound = this.sounds[soundKey.data];
      if (sound.isPlaying) sound.stop();
      sound.play();
    });
  }
}
