import '../style.css';
import '../style-login.css';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
// import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js';
import { EXRLoader } from 'three//examples/jsm/loaders/EXRLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { gsap } from 'gsap';
import CameraManager from './camera-manager';
import EventsManager from './events-manager';
import AppData from './appData';
import UIManager from './ui-manager';
// import SceneMaskVertexShader from '../Shaders/SceneMask/vertex.glsl';
// import SceneMaskFragmentShader from '../Shaders/SceneMask/fragment.glsl';
import THREEx from '../utils/renderStats/threex.rendererstats';
import Stats from 'stats.js';
import { Vector3 } from 'three';
import Localization from '../utils/localization/localization';
import SoundManager from './sound-manager';
import { GUI } from 'dat.gui';

export default class SceneManager {
    constructor() {
        window.sceneManager = this;
        this.selectedModelIndex = 0;
        this.selectedModelData = null;
        this.environmentMap = null;
        this.keyLight = null;
        this.keyLightOffset = new Vector3(0, 0, 0);
        this.keyLightDistance = 7.5;

        // console.log('SceneManager - constructor - this.selectedModelData =', this.selectedModelData);

        this.init();
        this.subscribeToEvents();
    }

    init() {
        // URL params:
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        // console.log('params:', params);
        // console.log('SceneManager - init - params', params, '&&', params.product, '&&', parseInt(params.product));
        if (params && params.product && parseInt(params.product)) {
            this.selectedModelIndex = parseInt(params.product);
        }

        // Loaders:
        this.sceneIsReady = false;
        // const loadingBarElement = document.querySelector('.loading-bar');
        const loadingManager = new THREE.LoadingManager(
            // Load completed:
            () => {
                // Wait a little:
                window.setTimeout(() => {
                    // Animate overlay:
                    // gsap.to(overlayMaterial.uniforms.uAlpha, { value: 0, duration: 1, delay: 0 });
                    this.uiManager.showHideLoading(false);

                    // Update loadingBarElement:
                    // loadingBarElement.classList.add('ended');
                    // loadingBarElement.style.transform = '';

                    this.sceneIsReady = true;
                }, 300);
            },

            // Progress:
            (itemUrl, itemsLoaded, itemsTotal) => {
                this.uiManager.showHideLoading(true);
                // loadingBarElement.classList.remove('hidden');
                // loadingBarElement.classList.remove('ended');
                // Calculate the progress and update the loadingBarElement:
                const progressRatio = itemsLoaded / itemsTotal;
                // console.log('LoadingManager', itemUrl.substring(0, 300), ' - progressRatio', progressRatio);
                // loadingBarElement.style.transform = `scaleX(${progressRatio})`;
            }
        );
        this.dracoLoader = new DRACOLoader();
        this.dracoLoader.setDecoderPath( './draco/');
        this.gltfLoader = new GLTFLoader(loadingManager);
        this.gltfLoader.setDRACOLoader(this.dracoLoader);
        this.cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager);
        this.textureLoader = new THREE.TextureLoader(loadingManager);
        this.textureLoader.load('./textures/mask/mask4.png', (texture) => {});

        // Canvas:
        const canvas = this.canvas = document.querySelector('canvas.webgl');
        console.log('SceneManager - init() - canvas:', canvas);

        // Scene:
        const scene = this.scene = new THREE.Scene();
        AppData.init(scene);

        // GUI:
        if (AppData.enableFpsMeter) {
            this.rendererStats = new THREEx.RendererStats();
            this.rendererStats.domElement.style.position = 'absolute';
            this.rendererStats.domElement.style.left = '0px';
            this.rendererStats.domElement.style.top = '60px';
            document.body.appendChild(this.rendererStats.domElement); // Comment to hide dev stats

            this.statsGui = new Stats();
            this.statsGui.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
            document.body.appendChild(this.statsGui.dom); // Comment to hide dev stats
        }

        // Renderer:
         const renderer = this.renderer = new THREE.WebGLRenderer({
            canvas: canvas,
            antialias: false,
            alpha: true,
        });
        // renderer.setClearColor(0xD39A2F, 1);
        renderer.autoClear = false;
        renderer.physicallyCorrectLights = true;
        renderer.outputEncoding = THREE.sRGBEncoding;
        renderer.toneMapping = THREE.ACESFilmicToneMapping;
        renderer.toneMappingExposure = 1.75;//2.3;
        // renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        renderer.setSize(AppData.sizes.width, AppData.sizes.height);
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        renderer.gammaOutput = true;
        // renderer.gammaFactor = 2.2;
        // renderer.setClearColor('#39414D'); // 424549

        // Environment map:
        this.pmremGenerator = new THREE.PMREMGenerator(renderer);
        this.pmremGenerator.compileEquirectangularShader();
        this.updateEnvironment('./textures/environmentMaps/showroom3.hdr');

        /* const backgroundEnv = new RGBELoader()
            .setDataType(THREE.HalfFloatType)
            .load(`./textures/environmentMaps/showroom2.hdr`, (texture) => {
                console.log('SceneManager - .load - texture', texture);
                // backgroundEnv.magFilter = THREE.LinearFilter;
                // backgroundEnv.minFilter = THREE.LinearMipmapLinearFilter;

                texture.mapping = THREE.EquirectangularReflectionMapping;
                scene.background = texture;
        }); */

        /* this.textureLoader
            .load(`./textures/environmentMaps/machine_shop_02-2k.jpg`, (texture) => {
                texture.mapping = THREE.EquirectangularReflectionMapping;
                scene.background = texture;
        }); */

        this.updateAllMaterials();

        // Camera:
        // Base camera
        this.cameraManager = new CameraManager(scene, renderer, canvas);

        // Composer:
        const compWidth = AppData.sizes.width * window.devicePixelRatio;
        const compHeight = AppData.sizes.height * window.devicePixelRatio;
        const composer = this.composer = new EffectComposer(renderer, new THREE.WebGLRenderTarget(compWidth, compHeight, {
            minFilter: THREE.LinearFilter,
            magFilter: THREE.LinearFilter,
            format: THREE.RGBAFormat,
            stencilBuffer: false,
        }));

        // RenderPass:
        const renderPass = new RenderPass(scene, this.cameraManager.camera);

        /*
        const maskPass = this.maskPass = new ShaderPass({
            vertexShader: SceneMaskVertexShader,
            fragmentShader: SceneMaskFragmentShader,
            uniforms: {
                // The underlying image:
                "tDiffuse": { value: null },
                // The alpha mask:
                "tMask": { value: null },
                "str": { value: null },
                "adjustX": { value: AppData.isLandscape() ? 0.5 : 0 },
                "adjustY": { value: AppData.isLandscape() ? 0 : 0.1 },
            },
        });

        maskPass.renderToScreen = true;
        maskPass.uniforms.str.value = 0; // Mask strength

        const imgUrlLandscape = './textures/mask/mask4.png';
        this.textureLoader.load(imgUrlLandscape, (tex) => {
            this.landscapeMask = tex;
            tex.minFilter = THREE.LinearFilter;
            tex.magFilter = THREE.LinearFilter;
            if (AppData.isLandscape()) maskPass.uniforms.tMask.value = tex;
        });

        const imgUrlPortrait = './textures/mask/mask-portrait.png';
        this.textureLoader.load(imgUrlPortrait, (tex) => {
            this.portraitMask = tex;
            tex.minFilter = THREE.LinearFilter;
            tex.magFilter = THREE.LinearFilter;
            if (AppData.isPortrait()) maskPass.uniforms.tMask.value = tex;
        });
        */

        // FXAA:
        const fxaaPass = this.fxaaPass = new ShaderPass(FXAAShader);
        fxaaPass.material.uniforms['resolution'].value.x = 1 / (canvas.offsetWidth * window.devicePixelRatio);
        fxaaPass.material.uniforms['resolution'].value.y = 1 / (canvas.offsetHeight * window.devicePixelRatio);

        // const smaaPass = new SMAAPass(window.innerWidth * renderer.getPixelRatio(), window.innerHeight * renderer.getPixelRatio());

        composer.addPass(renderPass);
        // composer.addPass(maskPass);
        composer.addPass(fxaaPass);
        // composer.addPass(smaaPass);

        // Raycaster:
        this.raycaster = new THREE.Raycaster();

        // UI:
        this.uiManager = new UIManager(scene, renderer, canvas);

        window.addEventListener('resize', () => {
            this.resize();
        })
        this.resize();

        /*EventsManager.addEventListener(AppData.Events.onLanguageChanged, () => {
            Localization.translateAll();
        });*/

        // Sound
        this.soundManager = new SoundManager(scene, renderer, canvas);

        // Animate:
        this.tick();
    }

    start() {
        this.uiManager.start();
        this.startScene();
        window.menuManager.checkParams2();
    }

    resize() {
        // Update sizes:
        AppData.sizes.width = window.innerWidth;
        AppData.sizes.height = window.innerHeight;

        // Update camera:
        this.cameraManager.resizeCameras();

        // Update renderer:
        this.renderer.setSize(AppData.sizes.width, AppData.sizes.height);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

        // Update composer resolution:
        this.composer.setSize(AppData.sizes.width, AppData.sizes.height);
        this.composer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

        // Update FXAA resolution:
        this.fxaaPass.material.uniforms['resolution'].value.x = 1 / (this.canvas.offsetWidth * window.devicePixelRatio);
        this.fxaaPass.material.uniforms['resolution'].value.y = 1 / (this.canvas.offsetHeight * window.devicePixelRatio);

        // Update mask:
        /* if (AppData.isLandscape()) {
            this.maskPass.material.uniforms['adjustX'].value = 0.5;
            this.maskPass.material.uniforms['adjustY'].value = 0;
            if (this.landscapeMask) this.maskPass.uniforms.tMask.value = this.landscapeMask;
        }
        else { // Portrait
            this.maskPass.material.uniforms['adjustX'].value = 0;
            this.maskPass.material.uniforms['adjustY'].value = 0.1;
            if (this.portraitMask) this.maskPass.uniforms.tMask.value = this.portraitMask;
        } */

        EventsManager.dispatchEvent({ type: AppData.Events.onResize });
    }

    subscribeToEvents() {
        EventsManager.addEventListener(AppData.Events.productSelected, (data) => {
            // console.log('SceneManager - Event - productSelected. data:', data);
            // this.overlayMaterial.uniforms.uAlpha.value = 1; // Fade to black loading screen
            this.uiManager.showHideLoading(true);////////
            this.selectModel(data.data.id);
            //this.selectModelFromData(data.data);
        });
        EventsManager.addEventListener(AppData.Events.previousProductPressed, this.previousProductPressed.bind(this));
        EventsManager.addEventListener(AppData.Events.nextProductPressed, this.nextProductPressed.bind(this));

        EventsManager.addEventListener(AppData.Events.previousDetailPressed, this.previousDetailPressed.bind(this));
        EventsManager.addEventListener(AppData.Events.nextDetailPressed, this.nextDetailPressed.bind(this));
    }

    startScene() {
        console.log('>>>>> SceneManager - startScene() <<<<<');
        // Load model:
        // window.setTimeout(() => {
            this.uiManager.showHideRoomsSelector(true);
        // }, 250);

        // Fullscreen:
        if (AppData.isMobile()) {
            const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement;
            if (!fullscreenElement) {
                // const canvas = document.getElementById('canvas');
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                }
                else if (document.documentElement.webkitRequestFullscreen) {
                    document.documentElement.webkitRequestFullscreen();
                }
            }
        }
    }

    selectModelFromData(data) {
        // console.log('SceneManager - selectModelFromData - data', data, ', AppData.productsFromDB:', AppData.productsFromDB);
        const newModelIndex = AppData.productsFromDB.indexOf(data);
        // console.log('SceneManager - selectModelFromData - newModelIndex', newModelIndex);
    }

    selectModel(newModelIndex) {
        // console.warn('SceneManager - selectModel() - newModelIndex:', newModelIndex);
        if (newModelIndex === null || newModelIndex === undefined) console.error('selectModel() newModelIndex is null');
        this.selectedModelIndex = newModelIndex;

        this.removeCurrentModel();

        if (this.selectedModelIndex < 0 || this.selectedModelIndex > AppData.productsFromDB.length - 1) {
            this.selectedModelIndex = 0;
        }

        this.selectedModelData = AppData.productsFromDB[this.selectedModelIndex];
        // console.log('SceneManager - selectModel() - this.selectedModelIndex:', this.selectedModelIndex, 'this.selectedModelData =', this.selectedModelData);
        if (this.selectedModelData.cameraPosition) {
            this.cameraManager.initialCameraPosition.set(
                this.selectedModelData.cameraPosition.x,
                this.selectedModelData.cameraPosition.y,
                this.selectedModelData.cameraPosition.z
            );
            // console.log('SceneManager - selectModel() - this.cameraManager.initialCameraPosition:', this.cameraManager.initialCameraPosition);
        }
        this.loadModel(this.selectedModelData);

        this.uiManager.showHideIntroBackground(false);
        this.uiManager.showHideRoomBackground(false);
        this.uiManager.showHideModelBackground(true);

        gtag('event', 'machine_selected', { 'machine_name': this.selectedModelData.productName }); // Analytics
        this.uiManager.updateUrlToMachine(this.selectedModelData);
    }

    loadModel(modelData) {
        // console.warn('SceneManager - loadModel() - modelData:', modelData);
        // Model:
        this.gltfLoader.load(
            modelData.file,
            (gltf) => {
                this.currentModel = gltf;
                // console.log('SceneManager - loadModel - this.currentModel', this.currentModel);
                if (modelData.scale) gltf.scene.scale.set(modelData.scale.x, modelData.scale.y, modelData.scale.z);
                if (modelData.position) gltf.scene.position.set(modelData.position.x, modelData.position.y, modelData.position.z);
                if (modelData.rotation) gltf.scene.rotation.set(modelData.rotation.x * Math.PI, modelData.rotation.y * Math.PI, modelData.rotation.z * Math.PI);
                this.scene.add(gltf.scene);

                // Update environment:
                if (modelData.environmentFile && modelData.environmentFile.length > 0) this.updateEnvironment(modelData.environmentFile);

                // Update all materials:
                this.updateAllMaterials();

                // Dispatch loaded product event:
                EventsManager.dispatchEvent({ type: AppData.Events.productLoaded, data: this.selectedModelData });
            }
        );
    }

    updateEnvironment(imagePath) {
        // console.log('SceneManager - updateEnvironment() - imagePath:', imagePath);
        /*this.envCubeMap = new RGBELoader()
            .setDataType(THREE.HalfFloatType)
            .load(imagePath, (texture) => {
                // this.envCubeMap.encoding = THREE.sRGBEncoding;
                const hdrRenderTarget = this.pmremGenerator.fromEquirectangular(texture);
                this.environmentMap = hdrRenderTarget.texture;
                // this.envCubeMap.magFilter = THREE.NearestMipmapNearestFilter;
                this.envCubeMap.needsUpdate = true;

                // texture.mapping = THREE.EquirectangularReflectionMapping;
                // scene.background = texture;

                const exrCubeRenderTarget = this.pmremGenerator.fromEquirectangular(this.envCubeMap);
                this.environmentMap = exrCubeRenderTarget.texture;
                this.scene.environment = this.environmentMap;
            }
        );*/

        let exrCubeRenderTarget;

        const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
        pmremGenerator.compileEquirectangularShader();

        const envCubeMap = new RGBELoader()
            .setDataType(THREE.HalfFloatType)
            .load(imagePath, (texture) => {
                // envCubeMap.encoding = THREE.sRGBEncoding;
                const hdrRenderTarget = pmremGenerator.fromEquirectangular(texture);
                this.environmentMap = hdrRenderTarget.texture;
                // envCubeMap.magFilter = THREE.NearestMipmapNearestFilter;
                envCubeMap.needsUpdate = true;

                // texture.mapping = THREE.EquirectangularReflectionMapping;
                // scene.background = texture;

                exrCubeRenderTarget = pmremGenerator.fromEquirectangular(envCubeMap);
                this.environmentMap = exrCubeRenderTarget.texture;
                this.scene.environment = this.environmentMap;
        });
    }

    removeCurrentModel() {
        // console.log('SceneManager - removeCurrentModel() - this.currentModel:', this.currentModel);
        if (this.currentModel) {
            //this.currentModel.dispose();
            this.scene.remove(this.currentModel.scene);
            // console.log('SceneManager - selectModel() - before remove this.selectedModelData =', this.selectedModelData);
            if (this.selectedModelData) this.uiManager.removePoints(this.selectedModelData.points);
            this.uiManager.closeDescription();
            this.cameraManager.resetCamera();
        }
        this.selectedModelData = null;
    }

    previousProductPressed() {
        this.selectedModelIndex--;
        if (this.selectedModelIndex < 0) {
            this.selectedModelIndex = AppData.productsFromDB.length - 1;
        }
        console.log('previousProductPressed() this.selectedModelIndex:', this.selectedModelIndex);
        this.selectModel(this.selectedModelIndex);
    }

    nextProductPressed() {
        this.selectedModelIndex++;
        if (this.selectedModelIndex > AppData.productsFromDB.length - 1) {
            this.selectedModelIndex = 0;
        }
        console.log('nextProductPressed() this.selectedModelIndex:', this.selectedModelIndex);
        this.selectModel(this.selectedModelIndex);
    }

    selectDetail(point) {
        this.selectedPointID = this.selectedModelData.points.indexOf(point);
        const analyticsString = `${this.selectedModelData.productName}_point${point.id}`;
        // console.log('selectDetail() point:', point, ', this.selectedPointID:', this.selectedPointID, ', analyticsString:', analyticsString);

        // Show description event, send point:
        EventsManager.dispatchEvent({ type: AppData.Events.showDetailDescription, data: point });
        // Zoom in event, send point:
        EventsManager.dispatchEvent({ type: AppData.Events.cameraZoomIn, data: point });
        gtag('event', 'machine_point_selected', { 'machine_point': analyticsString }); // Analytics
    }

    previousDetailPressed () {
        this.selectedPointID--;
        if (this.selectedPointID < 0) {
            this.selectedPointID = this.selectedModelData.points.length - 1;
        }
        // console.warn('previousDetailPressed this.selectedPointID', this.selectedPointID, ', point:', this.selectedModelData.points[this.selectedPointID]);
        this.selectDetail(this.selectedModelData.points[this.selectedPointID]);
    }

    nextDetailPressed () {
        this.selectedPointID++;
        if (this.selectedPointID > this.selectedModelData.points.length - 1) {
            this.selectedPointID = 0;
        }
        // console.warn('nextDetailPressed this.selectedPointID', this.selectedPointID, ', point:', this.selectedModelData.points[this.selectedPointID]);
        this.selectDetail(this.selectedModelData.points[this.selectedPointID]);
    }

    tick() {
        if (this.statsGui) this.statsGui.begin();
        this.cameraManager.tick();

        if (this.sceneIsReady && this.selectedModelData) {
            // Update points position:
            this.uiManager.updatePointsPosition(this.selectedModelData.points, this.raycaster);
        }

        // Render:
        // this.renderer.render(this.scene, this.cameraManager.camera);
        this.renderer.info.autoReset = false;
        // console.log('calls:', this.renderer.info.render.calls, 'triangles:', this.renderer.info.render.triangles/*, 'composer:', this.composer*/);
        if (this.rendererStats) {
            this.rendererStats.update(this.renderer);
        }
        this.renderer.info.reset();
        this.composer.render();
        if (this.statsGui) this.statsGui.end();

        // Call tick again on the next frame:
        window.requestAnimationFrame(this.tick.bind(this));
    }

    updateAllMaterials () {
        this.scene.traverse((child) => {
            if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
                // child.material.envMap = this.environmentMap;
                child.material.envMapIntensity = AppData.envMapIntensity;
                child.material.needsUpdate = true;
                child.castShadow = true;
                child.receiveShadow = true;
            }
        });
    }
}