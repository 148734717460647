import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import AppData from './appData';
import EventsManager from './events-manager';
import { gsap } from 'gsap';

export default class CameraManager {
    constructor(scene, renderer, canvas) {
        AppData.cameraManager = this; // Save reference

        this.scene = scene;
        this.renderer = renderer;
        this.canvas = canvas;

        this.camera = null;
        this.controls = null;
        this.dampingFactor = 0.1;
        this.rotateSpeed = 1;
        this.cameraTweenDuration = 0.75;
        this.initialCameraPosition = new THREE.Vector3(2, 0.96, -6.52); // This is modified in config file (7, 2, -3)
        this.previousCameraPosition = new THREE.Vector3();
        this.previousCameraTarget = new THREE.Vector3();
        this.cameraisTweening = false;
        this.isZoomed = false;
        this.selectedPointData = null;
        this.listener = null;

        this.initialize();
        this.subscribeToEvents();
    }

    initialize() {
        this.createCamera();
    }

    subscribeToEvents() {
        EventsManager.addEventListener(AppData.Events.onResize, this.onWindowResize.bind(this));

        EventsManager.addEventListener(AppData.Events.cameraZoomIn, this.zoomIn.bind(this));
        EventsManager.addEventListener(AppData.Events.cameraZoomOut, this.zoomOut.bind(this));

        EventsManager.addEventListener(AppData.Events.productLoaded, (eventData) => {
            if (eventData.data !== undefined) this.resetCamera(true);
        });
    }

    onWindowResize() {
        if (this.selectedPointData && this.selectedPointData.cameraPosition) {
            this.moveToSelectedPoint();
        }
        if (this.selectedPointData && this.selectedPointData.cameraTarget) {
            this.targetToSelectedPoint();
        }
    }

    createCamera() {
        const camera = this.camera = new THREE.PerspectiveCamera(65, AppData.sizes.width / AppData.sizes.height, 0.25, 50);
        camera.position.set(this.initialCameraPosition.x, this.initialCameraPosition.y, this.initialCameraPosition.z);
        this.scene.add(camera);

        this.camHelper = new THREE.Object3D();
        this.scene.add(this.camHelper);
        // DEBUG:
        // const debugGeometry = new THREE.BoxGeometry(0.2, 0.2, 0.2);
        // const debugMaterial = new THREE.MeshStandardMaterial({ color: 0x00aa00 });
        // const debugCube = new THREE.Mesh(debugGeometry, debugMaterial);
        // this.camHelper.add(debugCube);
        //

        // Camera controls:
        const controls = this.controls = new OrbitControls(camera, document.querySelector('#input-detector'));
        // controls.enableZoom = false;
        // controls.enablePan = false;
        controls.enableDamping = true;
        controls.dampingFactor = this.dampingFactor;
        controls.rotateSpeed = this.rotateSpeed;
        controls.minDistance = AppData.cameraMinDistance;
        controls.maxDistance = AppData.cameraMaxDistance;
        controls.maxPolarAngle = Math.PI * 0.57;
        controls.update();

        // Camera sound listener
        this.listener = new THREE.AudioListener();
        camera.add(this.listener);
    }

    resetCamera(instant = false) {
        this.isZoomed = false;
        if (this.positionTwn) this.positionTwn.kill();
        if (this.targetTwn) this.targetTwn.kill();
        // console.warn('resetCamera() initialCameraPosition:', this.initialCameraPosition.x, this.initialCameraPosition.y, this.initialCameraPosition.z);

        // Tween:
        this.positionTwn = gsap.to(this.camera.position, {
            duration: instant ? 0 : this.cameraTweenDuration,
            x: this.initialCameraPosition.x,
            y: this.initialCameraPosition.y,
            z: this.initialCameraPosition.z,
            ease: 'sine.inOut',
            onComplete: this.zoomOutCompleted.bind(this)
        });
        this.targetTwn = gsap.to(this.controls.target, {
            duration: instant ? 0 : this.cameraTweenDuration,
            x: 0,
            y: 0,
            z: 0,
            ease: 'sine.inOut'
        });
    }

    resizeCameras() {
        this.camera.aspect = AppData.sizes.width / AppData.sizes.height
        this.camera.updateProjectionMatrix()
    }

    tick() {
        // Update controls
        /*if (!this.isZoomed) */this.controls.update();
    }

    zoomIn(eventData) {
        const pointData = eventData.data;
        // console.log('CameraManager - zoomIn() - eventData:', eventData, '\n pointData:', pointData);
        if (/*this.cameraisTweening || */!pointData) return;
        this.selectedPointData = pointData;
        this.cameraisTweening = true;
        this.isZoomed = true;
        if (this.positionTwn) this.positionTwn.kill();
        if (this.targetTwn) this.targetTwn.kill();

        // console.log('------ CameraManager -> zoomIn this.cameraisTweening', this.cameraisTweening);
        setTimeout(() => { // Added minumum delay so MouseUp is registered on this.controls before disabling it. Without delay, the mouse is still dragging the camera when enabling it again
            this.controls.enabled = false;
            this.controls.dampingFactor = 0.5;
            this.controls.minDistance = 0.1;
            this.controls.maxDistance = 20;
            if (this.selectedPointData && this.selectedPointData.cameraPosition) {
                this.moveToSelectedPoint();
            }
            if (this.selectedPointData && this.selectedPointData.cameraTarget) {
                this.targetToSelectedPoint();
            }
        }, 5);
    }

    moveToSelectedPoint() {
        const pointData = this.selectedPointData;
        // ADJUST
        // console.warn('> pointData =>', pointData);
        this.camHelper.position.x = pointData.cameraPosition.x;
        this.camHelper.position.y = pointData.cameraPosition.y;
        this.camHelper.position.z = pointData.cameraPosition.z;
        const cameraPositionVector = new THREE.Vector3(pointData.cameraPosition.x, pointData.cameraPosition.y, pointData.cameraPosition.z);
        const cameraTargetVector = new THREE.Vector3(pointData.cameraTarget.x, pointData.cameraTarget.y, pointData.cameraTarget.z);
        this.camHelper.lookAt(cameraTargetVector);
        /*let camAdjust = -0.4 * AppData.GetAspectRatio(true);
        if (AppData.isPortrait()) camAdjust = 0;
        // console.log('moveToSelectedPoint() - camAdjust:', camAdjust, '  camHelper.position:', this.camHelper.position.x, this.camHelper.position.y, this.camHelper.position.z);
        this.camHelper.translateX(camAdjust);
        // console.log('moveToSelectedPoint() camHelper.position AFTER translateX:', this.camHelper.position.x, this.camHelper.position.y, this.camHelper.position.z);
*/

        const distance = cameraPositionVector.distanceTo(cameraTargetVector);
        const camAdjust = 0.33 * AppData.GetAspectRatio(true) * distance;
        // DEBUG:
        // const debugGeometry = new THREE.BoxGeometry(0.1, 0.1, 0.1);
        // const debugMaterial = new THREE.MeshBasicMaterial({ color: 0x0000aa });
        // const debugCube0 = new THREE.Mesh(debugGeometry, debugMaterial);
        // debugCube0.position.set(this.camHelper.position.x, this.camHelper.position.y, this.camHelper.position.z)
        // this.scene.add(debugCube0);
        //
        this.camHelper.translateX(camAdjust);
        // END ADJUST

        // DEBUG:
        // const debugMaterial1 = new THREE.MeshBasicMaterial({ color: 0x0044ff });
        // const debugCube1 = new THREE.Mesh(debugGeometry, debugMaterial1);
        // debugCube1.position.set(this.camHelper.position.x, this.camHelper.position.y, this.camHelper.position.z)
        // this.scene.add(debugCube1);
        //

        // console.log('> pointData.cameraPosition:', pointData.cameraPosition, 'previousCameraPosition.equals new THREE.Vector3?', this.previousCameraPosition.equals(new THREE.Vector3()));
        if (this.previousCameraPosition.equals(new THREE.Vector3())) this.previousCameraPosition.copy(this.camera.position);
        // Tween position:
        // const xPos = pointData.cameraPosition.x;
        // const yPos = pointData.cameraPosition.y;
        // const zPos = pointData.cameraPosition.z;
        const xPos = this.camHelper.position.x;
        const yPos = this.camHelper.position.y;
        const zPos = this.camHelper.position.z;
        this.positionTwn = gsap.to(this.camera.position, {
            duration: this.cameraTweenDuration,
            x: xPos,
            y: yPos,
            z: zPos,
            ease: 'power2.out',
            onComplete: this.zoomInCompleted.bind(this), onCompleteParams: [pointData],
            //paused: true,
        });
    }

    targetToSelectedPoint() {
        const pointData = this.selectedPointData;
        // crear GameObject this.camHelper en CamPos
        // this.camHelper.lookAt(target)
        // this.camHelper.rotateY(-0.05)
        // camera.rotation tween to this.camHelper.rotation
        // console.warn('> pointData.cameraTarget =>', pointData.cameraTarget, 'previousCameraTarget.equals new THREE.Vector3?', this.previousCameraTarget.equals(new THREE.Vector3()));

        this.camHelper.position.x = pointData.cameraTarget.x;
        this.camHelper.position.y = pointData.cameraTarget.y;
        this.camHelper.position.z = pointData.cameraTarget.z;

        const cameraPositionVector = new THREE.Vector3(pointData.cameraPosition.x, pointData.cameraPosition.y, pointData.cameraPosition.z);
        this.camHelper.lookAt(cameraPositionVector);
        // ADJUST
        const cameraTargetVector = new THREE.Vector3(pointData.cameraTarget.x, pointData.cameraTarget.y, pointData.cameraTarget.z);
        const distance = cameraPositionVector.distanceTo(cameraTargetVector);
        let camAdjust = 0;
        if (AppData.isLandscape()) {
            camAdjust = -0.33 * AppData.GetAspectRatio(true) * distance;
            this.camHelper.translateX(camAdjust);
        }
        else {
            camAdjust = -0.32 * distance;
            this.camHelper.translateY(camAdjust);
        }
        // END ADJUST

        if (this.previousCameraTarget.equals(new THREE.Vector3())) this.previousCameraTarget.copy(this.controls.target);
        // Tween target aim:
        this.targetTwn = gsap.to(this.controls.target, {
            duration: this.cameraTweenDuration,
            // x: pointData.cameraTarget.x,
            // y: pointData.cameraTarget.y,
            // z: pointData.cameraTarget.z,
            x: this.camHelper.position.x,
            y: this.camHelper.position.y,
            z: this.camHelper.position.z,
            ease: 'power2.out',
            //onComplete: () => this.positionTwn.play(),
        });
    }

    zoomOut() {
        this.selectedPointData = null;
        //if (this.cameraisTweening) return;
        this.cameraisTweening = true;
        if (this.positionTwn) this.positionTwn.kill();
        if (this.targetTwn) this.targetTwn.kill();
        // Tween:
        this.positionTwn = gsap.to(this.camera.position, {
            duration: this.cameraTweenDuration,
            x: this.previousCameraPosition.x,
            y: this.previousCameraPosition.y,
            z: this.previousCameraPosition.z,
            ease: 'sine.inOut',
            onComplete: this.zoomOutCompleted.bind(this)
        });
        this.targetTwn = gsap.to(this.controls.target, {
            duration: this.cameraTweenDuration,
            // x: this.previousCameraTarget.x,
            // y: this.previousCameraTarget.y,
            // z: this.previousCameraTarget.z,
            x: 0,
            y: 0,
            z: 0,
            ease: 'sine.inOut'
        });
    }

    zoomInCompleted(data) {
        this.cameraisTweening = false;
        // console.log('-> CameraManager -> zoomInCompleted -> this.cameraisTweening', this.cameraisTweening);
        if (data) {
            EventsManager.dispatchEvent({ type: 'showUI', data: data });
        }
    }

    zoomOutCompleted() {
        // console.log('->| CameraManager -> zoomOutCompleted this.cameraisTweening', this.cameraisTweening);
        this.cameraisTweening = false;
        this.isZoomed = false;
        this.controls.enabled = true;
        // this.controls.enableDamping = true;
        this.controls.dampingFactor = this.dampingFactor;
        this.controls.minDistance = AppData.cameraMinDistance;
        this.controls.maxDistance = AppData.cameraMaxDistance;

        // Restart previous state:
        this.previousCameraPosition = new THREE.Vector3();
        this.previousCameraTarget = new THREE.Vector3();
        // console.log('ZOOMOUT controls.target:', this.controls.target);
    }
}
