import AppData from './Managers/appData';
import EventsManager from './Managers/events-manager';
import Localization from './utils/localization/localization';

export default class MenuManager {
  constructor() {
    console.log('MenuManager - constructor');
    //throw new Error('AbstractClassError');
    this.initialize();
  }

  initialize() {
    this.getElements();
    this.subscribeToEvents();
  }

  subscribeToEvents() {
    // When the page content is ready...
    document.addEventListener('DOMContentLoaded', () => {
      console.log('MenuManager - DOMContentLoaded');
      Localization.initialize();
      // Check URL param and show line/machine:
      // console.log('MenuManager - window.firebaseInterface', window.firebaseInterface);
      //setTimeout(() => {
        console.log('MenuManager - setTimeout - checkParams');
        this.checkParams();
      //}, 1);
    });
  }

  getElements() {
    this.enterExperienceDiv = document.querySelector('#experience-div');

    this.loginContainer = document.querySelector('#login-div');

    this.experienceLoginBtn = document.querySelector('#experience-login');
    this.experienceLoginBtn.addEventListener('click', () => {
      EventsManager.dispatchEvent({ type: AppData.Events.playSound, data: 'button_01' });
      console.log('experienceLoginBtn click. firebaseInterface.isBusyLoging:', window.firebaseInterface.isBusyLoging, ', firebaseInterface.isLoged:', window.firebaseInterface.isLoged);
      if (!window.firebaseInterface || window.firebaseInterface.isBusyLoging || window.firebaseInterface.isLoged) return;
      this.showHideEnterExperience(false);
      setTimeout(() => {
        this.showHideLogin(true);
      }, 400);
    });

    this.loginGoBackBtn = document.querySelector('#login-go-back');
    this.loginGoBackBtn.addEventListener('click', () => {
      EventsManager.dispatchEvent({ type: AppData.Events.playSound, data: 'button_01' });
      this.showHideLogin(false);
      setTimeout(() => {
        this.showHideEnterExperience(true);
      }, 400);
    });

    this.introVideoElement = document.querySelector('#intro-video-container');
    this.showHideIntroVideo(false);

    // Language buttons:
    this.languageMenu = document.querySelector('#language-menu');
    /* for (let i = 0; i < this.languageMenu.children.length; i++) {
      this.languageMenu.children[i].addEventListener('click', () => {
        this.selectLanguageButton(this.languageMenu.children[i].innerHTML);
      });
    } */
    this.langBtnEn = document.querySelector('#lang-en');
    this.langBtnIt = document.querySelector('#lang-it');
    this.langBtnEs = document.querySelector('#lang-es');
    this.langBtnFr = document.querySelector('#lang-fr');
    this.langBtnEn.addEventListener('click', () => {
      Localization.setLanguage('en');
      EventsManager.dispatchEvent({ type: AppData.Events.playSound, data: 'button_01' });
    });
    this.langBtnIt.addEventListener('click', () => {
      Localization.setLanguage('it');
      EventsManager.dispatchEvent({ type: AppData.Events.playSound, data: 'button_01' });
    });
    this.langBtnEs.addEventListener('click', () => {
      Localization.setLanguage('es');
      EventsManager.dispatchEvent({ type: AppData.Events.playSound, data: 'button_01' });
    });
    this.langBtnFr.addEventListener('click', () => {
      Localization.setLanguage('fr');
      EventsManager.dispatchEvent({ type: AppData.Events.playSound, data: 'button_01' });
    });
  }

  checkParams() {
    console.log('MenuManager - checkParams()');
    const url = new URL(window.location.href);
    const search_params = url.searchParams;
    if (search_params.get('line') || search_params.get('machine')) {
      window.firebaseInterface.doLoginAnonymously();
    }
  }

  checkParams2() {
    const url = new URL(window.location.href);
    const search_params = url.searchParams;
    const lineParam = search_params.get('line');
    const machineParam = search_params.get('machine');
    // console.log('MenuManager - checkParams2() line:', lineParam, ', machine:', machineParam);
    if (lineParam) {
      // console.log('MenuManager - checkParams() - param line:', lineParam);
      ///////window.sceneManager.uiManager.showHideLoading(true); // Show loading
      // console.log('MenuManager - checkParams() - login callback here');
      // console.log('MenuManager - checkParams - AppData.roomsFromDB', AppData.roomsFromDB);
      const room = AppData.roomsFromDB.find((room) => room.roomName === lineParam);
      if (room) {
        // console.log('MenuManager - checkParams2() - room:', room);
        const roomId = AppData.roomsFromDB.indexOf(room);
        // console.log('MenuManager - checkParams() - roomId:', roomId);
        if (roomId >= 0) window.sceneManager.uiManager.selectRoom(roomId);

        // Machine:
        if (machineParam) { // If param
          if (room.products.length > 1) { // the room has more than 1 products. If there is only 1, it will get selected automatically when selecting the room
            // console.log('MenuManager - checkParams2 - window.sceneManager.uiManager.roomPointsDatas', window.sceneManager.uiManager.roomPointsDatas);
            const pointData = window.sceneManager.uiManager.roomPointsDatas.find((data) => data.hoverText === machineParam);
            // console.log('MenuManager - checkParams() - param machine:', machineParam);
            //const pointData = room.products.find((product) => product.productName === machineParam);
            // console.log('MenuManager - checkParams2 - pointData', pointData);
            setTimeout(() => {
              window.sceneManager.uiManager.clickRoomPoint(pointData);
            }, 1);
          }
        }
      }
      else {
        // Show login
        this.backToLogin(true);
      }
    }
  }

  selectLanguageButton(langCodeString) {
    //const btn = this.languageMenu.children.find((element) => element.langCode === langCodeString);
    //console.log('selectLanguage()', langCodeString, btn);
    for (let i = 0; i < this.languageMenu.children.length; i++) {
      if (this.languageMenu.children[i].id.replace('lang-', '') === langCodeString) {
        this.languageMenu.children[i].classList.add('selected');
        this.languageMenu.children[i].classList.add('underline');
        // console.log('MenuManager - selectLanguage - this.languageMenu.children[i]', this.languageMenu.children[i]);
      }
      else {
        this.languageMenu.children[i].classList.remove('selected');
        this.languageMenu.children[i].classList.remove('underline');
      }
    }
  }

  showHideEnterExperience(newStatus = true) {
    // if (loginContainer) loginContainer.setAttribute("style", "display:none");
    if (this.enterExperienceDiv) {
      if (newStatus) {
        this.enterExperienceDiv.classList.remove("fade-out");
      }
      else this.enterExperienceDiv.classList.add("fade-out");
    }
  }

  showHideIntroVideo(newValue = true) {
    if (this.introVideoElement) {
      if (newValue) {
        this.introVideoElement.classList.remove('hidden');
        localStorage.setItem('intro-video-viewed', true);
      }
      else this.introVideoElement.classList.add('hidden');
    }
  }

  showHideLogin(newStatus = true) {
    // if (loginContainer) loginContainer.setAttribute("style", "display:none");
    if (this.loginContainer) {
      if (newStatus) {
        this.loginContainer.classList.remove("fade-out");
      }
      else this.loginContainer.classList.add("fade-out");
    }
  }

  backToLogin(showLoginForm = false) {
    window.sceneManager.uiManager.showHideRoomsSelector(false);
    window.sceneManager.uiManager.showHideIntroBackground(true);
    this.showHideEnterExperience(!showLoginForm);
    this.showHideLogin(showLoginForm);
    window.firebaseInterface.resetLogin();
    // And then checkParams2()
  }

  setLoginButtonText(newText) {
    console.warn('MenuManager - setLoginButtonText - newText', newText);
    const loginBtn = document.getElementById("loginBtn");
    loginBtn.value = newText;
  }
}
