import * as THREE from 'three';

/*export default class EventsManager {
    constructor() {
        this.Initialize();
    }

    Initialize() {
        //this.Events = new THREE.EventDispatcher();
    }
}*/
const EventsManager = new THREE.EventDispatcher();
export default EventsManager;
