import AppData from '../../Managers/appData';
import EventsManager from '../../Managers/events-manager';
import Translations from './strings-localized';

export default class Localization {
  constructor() {
    throw new Error('AbstractClassError');
  }

  static initialize() {
    this.availableLanguages = ['en', 'es', 'it', 'fr'];
    const querystring = window.location.search; // Get querystring
    this.params = new URLSearchParams(querystring); // Create URLSearchParams from querystring
    // this.setLanguage(localStorage.getItem('language'));
    this.setLanguage(this.params.get('lang'));
    Localization.translateAll();
  }

  static setLanguage(lan) {
    const userLang = navigator.language || navigator.userLanguage;

    if (lan && lan.length > 1) {
      this.lan = lan;
    }
    else {
      this.lan = localStorage.getItem('language') ||
      userLang.substring(0, 2);
    }
    if (!this.availableLanguages.find((availableLang) => availableLang === this.lan)) { // Check if language is supported
      this.lan = this.availableLanguages[0]; // Set default fallback language
    }
    console.log('Localization - setLanguage() - this.lan:', this.lan);
    localStorage.setItem('language', this.lan);
    Localization.updateUrlToLanguage(this.lan);
    window.menuManager.selectLanguageButton(this.lan);
    Localization.translateAll();
    EventsManager.dispatchEvent({ type: AppData.Events.onLanguageChanged });
  }

  static updateUrlToLanguage(selectedLanguage) {
    // console.warn('updateUrlToLanguage() - window.location', window.location.href, ', selectedLanguage:', selectedLanguage);
    const url = new URL(window.location.href);
    const search_params = url.searchParams;
    if (selectedLanguage) search_params.set('lang', selectedLanguage);
    else search_params.delete('lang');
    url.search = search_params.toString();
    // console.log('Localization - updateUrlToLanguage - url', url);
    const new_url = url.toString();
    // console.log('updateUrlToLanguage new_url:', new_url);

    const state = {};
    const title = '';
    history.replaceState(state, title, new_url);
  }

  static get(string) {
    if (!this.lan) this.setLanguage();

    if (Object.prototype.hasOwnProperty.call(Translations, string)) {
      if (Object.prototype.hasOwnProperty.call(Translations[string], this.lan)) {
        return Translations[string][this.lan];
      } else console.warn('[Localization] No lang for:', this.lan, string);
    } else console.warn('[Localization] Missing string:', string);
    return string;
  }

  static translateAll() {
    document.querySelectorAll('[localization-key]').forEach(Localization.translateElement); // Find all elements that have the key attribute
  }

  // Replace the inner text of the given HTML element with the translation in the active locale, corresponding to the element's localozation-key
  static translateElement(element) {
    const key = element.getAttribute('localization-key');
    // const translation = translations[locale][key];
    const translation = Localization.get(key);
    element.innerText = translation;
    element.value = translation;
  }
}
