import AppData from './Managers/appData';
import SceneManager from './Managers/scene-manager';
import Firebase from './utils/firebase/firebase';
import FirebaseInterface from './firebase-interface';
import MenuManager from './menu-manager';

window.sceneCallback = () => {
  //const sceneManager = new SceneManager(); // OLD
  sceneManager.start(); // NEW
  /*setTimeout(() => {
    console.log('setTimeout - checkParams2');
    menuManager.checkParams2();
  }, 2000);*/
}

const fireBaseInterface = new FirebaseInterface();
window.firebaseInterface = fireBaseInterface; // Save Reference
console.log('window.firebaseInterface:', window.firebaseInterface);
const sceneManager = new SceneManager(); // NEW
const menuManager = new MenuManager();
window.menuManager = menuManager; // Save Reference

console.log('APP init');

