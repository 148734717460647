const FirebaseConfig = Object.freeze(
  {
    apiKey: "AIzaSyAwyQLL7CVRClF_r5CGVPJtkwbUwBMQApg",
    authDomain: "ferraro-experience-66259.firebaseapp.com",
    databaseURL: "https://ferraro-experience-66259-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "ferraro-experience-66259",
    storageBucket: "ferraro-experience-66259.appspot.com",
    messagingSenderId: "880544996827",
    appId: "1:880544996827:web:accd747f44f848ad811bfe"
  },
);

export default FirebaseConfig;
