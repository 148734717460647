import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import FirebaseConfig from './firebase-conf';

export default class Firebase {
  constructor() {
    // This class has a singleton behaviour
    if (Firebase._instance) {
      this.user = null;
      return Firebase._instance
    }
    Firebase._instance = this;
  }

  initialize() {
    // Initialize Firebase
    console.log("[Firebase] Initializing app");
    firebase.initializeApp(FirebaseConfig);

    firebase.auth().onAuthStateChanged(this.onAuthStateChange);
  }

  /**
   * Create user with email and password on Firebase Auth
   * @param {string} email - Email of the user
   * @param {string} password - Password of the user
   * @return {Promise} - Promise of the firebase call (then: userCredential / catch: error)
   */
  createUserWithEmailAndPassword(email, password) {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  }

  /**
   * Login with email and password on Firebase Auth
   * @param {string} email - Email of the user
   * @param {string} password - Password of the user
   * @return {Promise} - Promise of the firebase call (then: userCredential / catch: error)
   */
   loginWithEmailAndPassword(email, password) {
    // console.log ("[Firebase] Try login:", email, password);
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  /**
   * Read from path on Firebase Realtime Database
   * @param {string} path - Path to read from
   * @return {Promise} - Promise of the firebase call (then: snapshot - should check if it exists by: 'if (snapshot.exists())' / catch: error)
   */
   readFromPath(path) {
    const dbRef = firebase.database().ref();
    return dbRef.child(path).get();
  }

  /**
   * Write on path on Firebase Realtime Database
   * @param {string} path - Path to read from
   * @param {object} obj - Object to write
   * @return {Promise} - Promise of the firebase call (then: snapshot - should check if it exists by: 'if (snapshot.exists())' / catch: error)
   */
   writeOnPath(path, obj) {
    const dbRef = firebase.database().ref();
    return dbRef.child(path).set(obj);
  }

  /**
   * Add a new element to a path on Firebase Realtime Database
   * @param {string} path - Path to read from
   * @param {object} path - Object to add
   * @return {Promise} - Promise of the firebase call
   */
   addElementToPath(path, obj) {
    const dbRef = firebase.database().ref();
    const newKey = dbRef.child(path).push().key;

    var updates = {};
    updates[path + '/' + newKey] = obj;

    return dbRef.update(updates);
  }

  /**
   * Login Anonymously on Firebase Auth
   * @return {Promise} - Promise of the firebase call (then: userCredential / catch: error)
   */
   loginAnonymously() {
    return firebase.auth().signInAnonymously();
  }

  onAuthStateChange(user) {
    if (user) {
      console.log("[Firebase] Auth State Change: ", user);
      // ...
    } else {
      console.log("[Firebase] User signed out: ", user);
    }
  }

  /**
   * Signout from Firebase Auth
   */
  signout() {
    return firebase.auth().signOut();
   }
  
}